import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  useTheme,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material';
import { DealStageModalProps } from '../../../types/DealStageActions';
import { makeAuthenticatedApiCall } from '../../../apiHelper';

const DealStageModal: React.FC<DealStageModalProps> = ({
  dealId,
  dealPipelineId,
  stages,
  pipelines,
  initialStageId,
  onRefresh,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedPipelineId, setSelectedPipelineId] = useState(dealPipelineId);
  const [selectedStageId, setSelectedStageId] = useState(initialStageId);
  const theme = useTheme();

  // Trouver l'index de l'étape actuelle
  // const currentStage = stages.find((stage: any) => stage.id === initialStageId);

  const handlePipelineChange = (event: SelectChangeEvent<number>) => {
    const pipelineId = event.target.value as number;
    setSelectedPipelineId(pipelineId);

    const firstStageOfPipeline = stages.find((stage) => stage.pipeline_id === pipelineId);
    setSelectedStageId(firstStageOfPipeline?.id.toString() || '');
  };

  useEffect(() => {
    setSelectedStageId(initialStageId);
  }, [initialStageId]);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleConfirmChange = async () => {
    setLoading(true);
    try {
      const response = await makeAuthenticatedApiCall('post', '/api/pipedrive/deals/update-stage-deal', {
        dealId,
        stageId: selectedStageId,
      });
      const data = response.data as { success: boolean }; // Type assertion
      if (data.success) {
        console.log('Stage updated successfully');
        await onRefresh();
      } else {
        console.error('Failed to update stage');
      }
    } catch (error) {
      console.error('Error updating stage:', error);
    } finally {
      setLoading(false);
      handleCloseModal();
    }
  };

  // Cette fonction découpe le nom de l'étape en fonction des tirets et des espaces, en conservant uniquement la partie avant le deuxième espace.
  const getStageName = (fullName: string) => {
    const parts = fullName.split(' - '); // Sépare au premier "-"
    const firstPart = parts[0];
    const additionalSplit = firstPart.split(' '); // Sépare le résultat au premier espace
    if (additionalSplit.length > 2) {
      // S'il y a plus de deux mots avant le tiret
      return `${additionalSplit[0]} ${additionalSplit[1]}`; // Combine les deux premiers mots
    }
    return firstPart; // Si moins de deux mots, retourne la partie initiale
  };

  return (
    <>
      <Button variant="outlined" onClick={handleOpenModal}>
        🔀 Changer l'étape
      </Button>
      <Dialog open={open} onClose={handleCloseModal}>
        <DialogTitle>Changer l'étape du deal</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="pipeline-select-label">Pipeline</InputLabel>
            <Select
              labelId="pipeline-select-label"
              id="pipeline-select"
              className="inputElement"
              value={selectedPipelineId}
              label="Pipeline"
              onChange={handlePipelineChange} // Pas besoin de casting ici si les types correspondent
            >
              {/* Affichage des options de pipeline avec le bon typage */}
              {pipelines.map((pipeline) => (
                <MenuItem key={pipeline.id} value={pipeline.id}>
                  {pipeline.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box sx={{ marginTop: 2 }}>
            {/* Affichage de la barre de progression des stages */}
            <Typography variant="body2" color="textSecondary">
              Étape du pipeline
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, flexWrap: 'wrap' }}>
              {stages
                .filter((stage) => stage.pipeline_id === selectedPipelineId)
                .sort((a, b) => a.order_nr - b.order_nr) // Assurez-vous que les étapes sont triées par leur order_nr
                .map((stage) => {
                  // Séparer le nom de l'étape au premier "-" et prendre la première partie
                  const stageName = getStageName(stage.name);

                  return (
                    <Box
                      key={stage.id}
                      sx={{
                        flexGrow: 1,
                        padding: theme.spacing(1),
                        textAlign: 'center',
                        cursor: 'pointer',
                        backgroundColor:
                          stage.id && initialStageId && stage.id.toString() === initialStageId.toString()
                            ? theme.palette.primary.main
                            : theme.palette.action.hover,
                        '&:hover': {
                          backgroundColor: theme.palette.primary.light,
                        },
                        position: 'relative', // Ajouté pour positionner le point au centre
                      }}
                      onClick={() => setSelectedStageId(stage.id.toString())}
                    >
                      <Typography variant="body2">{stageName}</Typography>
                      {selectedStageId === stage.id.toString() && (
                        <Box
                          sx={{
                            width: 10,
                            height: 10,
                            borderRadius: '50%',
                            backgroundColor: theme.palette.saffron.main,
                            position: 'absolute',
                            bottom: '-20px',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                          }}
                        ></Box>
                      )}
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Annuler</Button>
          <Button onClick={handleConfirmChange} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Valider'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DealStageModal;

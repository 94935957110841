import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material';

const stickyCellStyle = {
  background: '#262626',
  fontWeight: 'bold',
  minWidth: 50,
  color: 'white',
  position: 'sticky',
  top: 0,
  zIndex: 100,
};

interface AgencyDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  agencies: string[];
}

const AgencyDetailsDialog: React.FC<AgencyDetailsDialogProps> = ({ open, onClose, title, agencies }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Paper sx={{ marginTop: 2 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={stickyCellStyle}>Nom de l'agence</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {agencies.map((agency, index) => (
                <TableRow key={index}>
                  <TableCell>{agency}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default AgencyDetailsDialog;

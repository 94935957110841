import { Box, Step, StepLabel, Stepper, Typography, useMediaQuery } from '@mui/material';
import theme from '../../theme';

const steps = ['Identité du véhicule', 'Inspection', 'Frais à prévoir', 'Résumé', 'Photos', 'Envoi pour cotation'];

const Timeline = ({ activeStep }: { activeStep: number }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ width: '100%', marginBottom: 4 }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              StepIconProps={{
                style: {
                  color: theme.palette.saffron.main,
                  fontSize: isMobile ? '1rem' : 'default', // Adjust icon size for mobile
                },
              }}
            >
              <Typography
                variant={isMobile ? 'caption' : 'body2'}
                color={index === activeStep ? theme.palette.saffron.main : 'textSecondary'}
                sx={{
                  lineHeight: isMobile ? '1.2' : 'inherit', // Adjust line-height for mobile
                  display: 'block', // Ensure block display for line-height to take effect
                  marginTop: isMobile ? '0.5rem' : 'inherit', // Adjust margin for better spacing on mobile
                }}
              >
                {label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default Timeline;

import { useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { endOfMonth, format, startOfMonth, startOfYear, subDays } from 'date-fns';
import * as XLSX from 'xlsx';

import { useDetailedStatistics, useGetAgencies, useGetFranchise } from '../../hooks/useApiQueries';
import theme from '../../theme';
import { priceFormatter } from '../../utils';
import PurchasesDetailsDialog from './PurchasesDetailsDialog';
import SalesDetailsDialog from './SalesDetailsDialog';

const stickyCellStyle = {
  background: '#262626',
  fontWeight: 'bold',
  minWidth: 50,
  color: 'white',
  position: 'sticky',
  top: 0,
  zIndex: 100,
  fontSize: '14px',
  paddingLeft: 0,
};

const DetailedStatisticsPage: React.FC = () => {
  const [selectedFranchise, setSelectedFranchise] = useState<string>('');
  const [selectedAgency, setSelectedAgency] = useState<string>('');
  const [startDate, setStartDate] = useState<Date>(startOfYear(new Date()));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('agency');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [salesDetails, setSalesDetails] = useState<
    Array<{ regplate: string; pipedrive_deal_id: string; make: string; model: string; ext_url: string }>
  >([]);
  const [purchasesDialogOpen, setPurchasesDialogOpen] = useState(false);
  const [purchasesDetails, setPurchasesDetails] = useState<
    Array<{ regplate: string; pipedrive_deal_id: string; make: string; model: string; ext_url: string }>
  >([]);

  const { data: detailedStats, isLoading } = useDetailedStatistics(startDate, endDate, '', selectedAgency);

  const { data: franchiseData } = useGetFranchise();

  const handleDateRangeClick = (range: string) => {
    const today = new Date();
    switch (range) {
      case '30jours':
        setStartDate(subDays(today, 30));
        setEndDate(today);
        break;
      case 'mois':
        setStartDate(startOfMonth(today));
        setEndDate(endOfMonth(today));
        break;
      case 'annee':
        setStartDate(startOfYear(today));
        setEndDate(today);
        break;
      default:
        break;
    }
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSalesClick = (agency: string, salesData: any) => {
    setDialogTitle(`Détails des ventes - ${agency}`);
    setSalesDetails(salesData || []);
    setDialogOpen(true);
  };

  const handlePurchasesClick = (agency: string, purchasesData: any) => {
    setDialogTitle(`Détails des achats - ${agency}`);
    setPurchasesDetails(purchasesData || []);
    setPurchasesDialogOpen(true);
  };

  const handleFranchiseChange = (value: string) => {
    setSelectedFranchise(value);
  };

  const sortedStats =
    !isLoading &&
    detailedStats
      .slice()
      .map((stat: { salesDetails: any[] }) => {
        const totalNetMargin = stat.salesDetails.reduce((sum: number, sale: any) => sum + (sale.net_margin || 0), 0);
        return { ...stat, totalNetMargin };
      })
      .sort(
        (
          a: { [x: string]: any; salesDetails: any[]; totalNetMargin: any },
          b: { [x: string]: any; salesDetails: any[]; totalNetMargin: any },
        ) => {
          let aValue = a[orderBy] ?? 0;
          let bValue = b[orderBy] ?? 0;

          if (orderBy === 'ventes') {
            aValue = a.salesDetails[0] ?? 0;
            bValue = b.salesDetails[0] ?? 0;
          } else if (orderBy === 'net_margin') {
            aValue = a.totalNetMargin ?? 0;
            bValue = b.totalNetMargin ?? 0;
          }

          // Convert to numbers if the values are numeric strings
          if (!isNaN(aValue) && !isNaN(bValue)) {
            aValue = Number(aValue) ?? 0;
            bValue = Number(bValue) ?? 0;
          }

          if (aValue < bValue) {
            return order === 'asc' ? -1 : 1;
          }
          if (aValue > bValue) {
            return order === 'asc' ? 1 : -1;
          }
          return 0;
        },
      );

  const filteredStats =
    !isLoading &&
    sortedStats
      .filter((stat: any) => (selectedFranchise ? stat.franchise === selectedFranchise : true))
      .map((stat: { salesDetails: any[] }) => {
        const totalNetMargin = stat.salesDetails.reduce((sum: number, sale: any) => sum + (sale.net_margin || 0), 0);
        return { ...stat, totalNetMargin };
      })
      .sort(
        (
          a: { [x: string]: any; salesDetails: any[]; totalNetMargin: any },
          b: { [x: string]: any; salesDetails: any[]; totalNetMargin: any },
        ) => {
          let aValue = a[orderBy] ?? 0;
          let bValue = b[orderBy] ?? 0;

          if (orderBy === 'ventes') {
            aValue = a.salesDetails[0] ?? 0;
            bValue = b.salesDetails[0] ?? 0;
          } else if (orderBy === 'net_margin') {
            aValue = a.totalNetMargin ?? 0;
            bValue = b.totalNetMargin ?? 0;
          }

          // Convert to numbers if the values are numeric strings
          if (!isNaN(aValue) && !isNaN(bValue)) {
            aValue = Number(aValue) ?? 0;
            bValue = Number(bValue) ?? 0;
          }

          if (aValue < bValue) {
            return order === 'asc' ? -1 : 1;
          }
          if (aValue > bValue) {
            return order === 'asc' ? 1 : -1;
          }
          return 0;
        },
      );

  const handleExportToExcel = () => {
    // Créer le titre avec les informations de filtrage
    const titleRow = [
      {
        Titre: `Statistiques détaillées${selectedFranchise ? ` - ${selectedFranchise}` : ''} - Du ${format(startDate, 'dd/MM/yyyy')} au ${format(endDate, 'dd/MM/yyyy')}`,
      },
    ];

    // Préparer les données pour l'export
    const exportData = filteredStats.map((stat: any) => ({
      Agence: stat.agency,
      Franchise: stat.franchise,
      Cotations: stat.cotations,
      'Double-Cotations': stat.double_cotations,
      Achats: stat.achats,
      'Transformation (%)': Number(stat.transformation).toFixed(1),
      'Dont double-cotations': stat.double_cotation_transformations,
      'Nbr de cotations depuis le dernier achat': stat.cotations_since_last_purchase,
      Ventes: stat.salesDetails[0] ?? 0,
      'Montant net': stat.net_amount,
      'Marge nette': stat.totalNetMargin,
    }));

    // Créer un nouveau workbook
    const wb = XLSX.utils.book_new();

    // Créer la feuille avec le titre
    const ws = XLSX.utils.json_to_sheet(titleRow, { skipHeader: true });

    // Fusionner les cellules pour le titre (11 colonnes)
    if (!ws['!merges']) ws['!merges'] = [];
    ws['!merges'].push({ s: { r: 0, c: 0 }, e: { r: 0, c: 10 } });

    // Ajouter une ligne vide
    XLSX.utils.sheet_add_json(ws, [{}], { origin: -1, skipHeader: true });

    // Ajouter les données principales
    XLSX.utils.sheet_add_json(ws, exportData, { origin: -1 });

    // Ajuster la largeur des colonnes
    ws['!cols'] = [
      { wch: 40 }, // Agence
      { wch: 20 }, // Franchise
      { wch: 15 }, // Cotations
      { wch: 15 }, // Double-Cotations
      { wch: 15 }, // Achats
      { wch: 15 }, // Transformation
      { wch: 20 }, // Dont double-cotations
      { wch: 30 }, // Nbr cotations depuis dernier achat
      { wch: 15 }, // Ventes
      { wch: 15 }, // Montant net
      { wch: 15 }, // Marge nette
    ];

    // Ajouter la feuille au workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Statistiques détaillées');

    // Générer le fichier avec la date du jour
    const date = new Date().toISOString().split('T')[0];
    XLSX.writeFile(wb, `statistiques_detaillees_${date}.xlsx`);
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        maxWidth: '1600px',
        mx: 'auto',
        padding: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Grid container justifyContent="space-between" alignItems="flex-start">
        <Button
          variant="contained"
          color="primary"
          startIcon={<ArrowBackIcon />}
          onClick={() => window.history.back()}
          sx={{ marginBottom: 0, color: 'black', marginTop: 0 }}
        >
          Retour
        </Button>
        <Button
          onClick={handleExportToExcel}
          style={{
            position: 'relative',
            float: 'right',
            right: '0px',
            top: '0px',
            backgroundColor: theme.palette.saffron.main,
            zIndex: 1000,
          }}
        >
          <SaveIcon color="action" />
        </Button>
      </Grid>
      <Typography variant="body1" fontSize="30px" gutterBottom textAlign="center">
        Statistiques détaillées
      </Typography>
      <Grid container sx={{ maxWidth: '1600px', mx: 'auto', marginTop: theme.spacing(8) }}>
        <Grid item xs={12} sm={3} sx={{ marginBottom: theme.spacing(3) }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="franchise-select-label" className="inputElement">
              Franchise
            </InputLabel>
            <Select
              labelId="franchise-select-label"
              id="franchise-select"
              value={selectedFranchise}
              className="inputElement"
              onChange={(e) => handleFranchiseChange(e.target.value)}
              label="Franchise"
              sx={{ width: 250 }}
            >
              <MenuItem value="">
                <em>Toutes les franchises</em>
              </MenuItem>
              {franchiseData?.map((franchise: any) => (
                <MenuItem key={franchise.franchise} value={franchise.franchise}>
                  {franchise.franchise === 'Skipcar' ? 'Ewigo' : franchise.franchise}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={9} display="flex" justifyContent="flex-start">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid item xs={12} sm={3} flexDirection="column" display="flex">
              <DatePicker
                className="inputElement"
                sx={{ width: 250 }}
                label="Date de début"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="JJ/MM/AAAA"
                    inputProps={{
                      ...params.inputProps,
                      mask: '__/__/____',
                    }}
                  />
                )}
                format="dd/MM/yyyy"
              />
              <Box>
                <Typography
                  variant="body2"
                  sx={{ fontSize: '16px', textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => handleDateRangeClick('30jours')}
                >
                  30 jours glissants
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: '16px', textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => handleDateRangeClick('mois')}
                >
                  Mois en cours
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: '16px', textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => handleDateRangeClick('annee')}
                >
                  Année en cours
                </Typography>
              </Box>
            </Grid>

            <DatePicker
              label="Date de fin"
              className="inputElement"
              value={endDate}
              sx={{ width: 250 }}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="JJ/MM/AAAA"
                  inputProps={{
                    ...params.inputProps,
                    mask: '__/__/____',
                  }}
                />
              )}
              format="dd/MM/yyyy"
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      ) : (
        <Table component={Paper} sx={{ marginTop: theme.spacing(4) }}>
          <TableHead>
            <TableRow>
              <TableCell sx={stickyCellStyle}>
                <TableSortLabel
                  active={orderBy === 'agency'}
                  direction={orderBy === 'agency' ? order : 'asc'}
                  onClick={() => handleRequestSort('agency')}
                >
                  Agence
                </TableSortLabel>
              </TableCell>
              <TableCell sx={stickyCellStyle}>
                <TableSortLabel
                  active={orderBy === 'cotations'}
                  direction={orderBy === 'cotations' ? order : 'asc'}
                  onClick={() => handleRequestSort('cotations')}
                >
                  Cotations
                </TableSortLabel>
              </TableCell>
              <TableCell sx={stickyCellStyle}>
                <TableSortLabel
                  active={orderBy === 'double_cotations'}
                  direction={orderBy === 'double_cotations' ? order : 'asc'}
                  onClick={() => handleRequestSort('double_cotations')}
                >
                  Double-Cotations
                </TableSortLabel>
              </TableCell>
              <TableCell sx={stickyCellStyle}>
                <TableSortLabel
                  active={orderBy === 'achats'}
                  direction={orderBy === 'achats' ? order : 'asc'}
                  onClick={() => handleRequestSort('achats')}
                >
                  Achats
                </TableSortLabel>
              </TableCell>
              <TableCell sx={stickyCellStyle}>
                <TableSortLabel
                  active={orderBy === 'transformation'}
                  direction={orderBy === 'transformation' ? order : 'asc'}
                  onClick={() => handleRequestSort('transformation')}
                >
                  Transformation (%)
                </TableSortLabel>
              </TableCell>
              <TableCell sx={stickyCellStyle}>
                <TableSortLabel
                  active={orderBy === 'double_cotation_transformations'}
                  direction={orderBy === 'double_cotation_transformations' ? order : 'asc'}
                  onClick={() => handleRequestSort('double_cotation_transformations')}
                >
                  Dont double-cotations
                </TableSortLabel>
              </TableCell>
              <TableCell sx={stickyCellStyle}>
                <TableSortLabel
                  active={orderBy === 'cotations_since_last_purchase'}
                  direction={orderBy === 'cotations_since_last_purchase' ? order : 'asc'}
                  onClick={() => handleRequestSort('cotations_since_last_purchase')}
                >
                  Nbr de cotations depuis le dernier achat
                </TableSortLabel>
              </TableCell>
              <TableCell sx={stickyCellStyle}>
                <TableSortLabel
                  active={orderBy === 'ventes'}
                  direction={orderBy === 'ventes' ? order : 'asc'}
                  onClick={() => handleRequestSort('ventes')}
                >
                  Ventes
                </TableSortLabel>
              </TableCell>
              <TableCell sx={stickyCellStyle}>
                <TableSortLabel
                  active={orderBy === 'net_amount'}
                  direction={orderBy === 'net_amount' ? order : 'asc'}
                  onClick={() => handleRequestSort('net_amount')}
                >
                  Montant net
                </TableSortLabel>
              </TableCell>
              <TableCell sx={stickyCellStyle}>
                <TableSortLabel
                  active={orderBy === 'net_margin'}
                  direction={orderBy === 'net_margin' ? order : 'asc'}
                  onClick={() => handleRequestSort('net_margin')}
                >
                  Marge nette
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredStats.map((stat: any, index: number) => (
              <TableRow key={`${stat.agency}-${index}`}>
                <TableCell>{stat.agency}</TableCell>
                <TableCell>{stat.cotations}</TableCell>
                <TableCell>{stat.double_cotations}</TableCell>
                <TableCell
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handlePurchasesClick(stat.agency, stat.purchasesDetails)}
                >
                  {stat.achats}
                </TableCell>
                <TableCell>{Number(stat.transformation).toFixed(1)} %</TableCell>
                <TableCell>
                  {stat.double_cotation_transformations}
                  {stat.achats > 0
                    ? ' / ' +
                      ((Number(stat.double_cotation_transformations) / Number(stat.achats)) * 100).toFixed(1) +
                      ' %'
                    : ''}
                </TableCell>
                <TableCell
                  sx={{ color: Number(stat.cotations_since_last_purchase) > 50 ? 'red !important' : 'inherit' }}
                >
                  {stat.cotations_since_last_purchase ?? 0}
                </TableCell>
                <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSalesClick(stat.agency, stat.salesDetails)}>
                  {stat.salesDetails[0] ?? 0}
                </TableCell>
                <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSalesClick(stat.agency, stat.salesDetails)}>
                  {priceFormatter.format(stat.net_amount)}
                </TableCell>
                <TableCell sx={{ cursor: 'pointer' }} onClick={() => handleSalesClick(stat.agency, stat.salesDetails)}>
                  {priceFormatter.format(stat.totalNetMargin)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      <SalesDetailsDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={dialogTitle}
        data={salesDetails}
      />
      <PurchasesDetailsDialog
        open={purchasesDialogOpen}
        onClose={() => setPurchasesDialogOpen(false)}
        title={dialogTitle}
        data={purchasesDetails}
      />
    </Container>
  );
};

export default DetailedStatisticsPage;

import {
  Divider,
  Grid,
  Typography,
  TextField,
  Button,
  Tooltip,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tab,
  Tabs,
  Box,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { numberFormatter, formatSimpleDateToDDMMYYYYWithTime, formatPriceToNumber, Capitalize } from '../../utils';
import { CaseArbitrationProps } from '../../types/deal';
import theme from '../../theme';
import { useForm } from 'react-hook-form';
import { makeAuthenticatedApiCall } from '../../apiHelper';
import {
  useArbitrationDeal,
  useArbitratorDeals,
  useDatabaseDealInfoAuth,
  useDatabaseDeals,
} from '../../hooks/useApiQueries';
import { useAuth } from '../AuthContext';
import { useQueryClient } from '@tanstack/react-query';
import { PurchaseCalculator } from './PurchaseCalculator';
import { MandateCalculator } from './MandateCalculator';
import { DepositSaleCalculator } from './DepositSaleCalculator';

interface SubmissionType {
  type: 'arbitrator-submit' | 'supervisor-submit';
}

// Fonction pour extraire le message d'erreur ou retourner une chaîne vide
const getErrorMessage = (error: any) => {
  return typeof error?.message === 'string' ? error.message : '';
};

const CaseArbitration: React.FC<CaseArbitrationProps> = ({ dealId, extUrl, purchasePrice, encheresVoPrice }) => {
  const [isLoading, setIsLoading] = useState(true);

  const [arbitratorInputsDisabled, setArbitratorInputsDisabled] = useState<boolean>(false);
  const [supervisorInputsDisabled, setSupervisorInputsDisabled] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');

  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);
  const [submissionData, setSubmissionData] = useState<SubmissionType | null>(null);
  const [formSubmissionData, setFormSubmissionData] = useState<any>(null);

  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const themeMobile = useTheme();
  const isMobile = useMediaQuery(themeMobile.breakpoints.down('sm'));

  const { refetch: refetchArbitratorDeals } = useArbitratorDeals();
  const { refetch: refetchUseDatabaseDeals } = useDatabaseDeals();

  const { data: arbitrationDealInfo } = useArbitrationDeal(dealId);
  const { data: dealData } = useDatabaseDealInfoAuth(dealId);
  const { currentUser } = useAuth();

  const regPlate = dealData && dealData?.data?.registration_plate;
  const orgId = dealData && dealData?.data?.org_id;
  const model = dealData && `${Capitalize(dealData?.data?.make)} ${dealData?.data?.model}`;

  const {
    register,
    handleSubmit,
    trigger,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const depositSaleArbitrator = watch('deposit_sale_arbitrator');
  const straightTradeInArbitrator = watch('straight_trade_in_arbitrator');
  const thirtyDaySaleMandateArbitrator = watch('thirty_day_sale_mandate_arbitrator');
  const MarketPriceArbitrator = watch('market_price_arbitrator');
  const commentArbitrator = watch('arbitrator_comment');
  const lbcArbitrator = watch('arbitrator_lbc');
  const indicataArbitrator = watch('arbitrator_indicata');

  const depositSaleSupervisor = watch('deposit_sale_supervisor');
  const straightTradeInSupervisor = watch('straight_trade_in_supervisor');
  const thirtyDaySaleMandateSupervisor = watch('thirty_day_sale_mandate_supervisor');
  const MarketPriceSupervisor = watch('market_price_supervisor');
  const commentSupervisor = watch('supervisor_comment');
  const lbcSupervisor = watch('supervisor_lbc');
  const indicataSupervisor = watch('supervisor_indicata');

  const queryClient = useQueryClient();

  const handleArbitratorSubmit = async () => {
    // Clear existing errors
    clearErrors('market_price_arbitrator');

    // Vérification spécifique pour le champ 'market_price_arbitrator'
    if (!MarketPriceArbitrator) {
      // Définit une erreur si 'market_price_arbitrator' est vide ou non défini
      setError('market_price_arbitrator', {
        type: 'manual',
        message: 'Le prix du marché (Arbitre) est obligatoire',
      });
      return; // Arrête la soumission si l'erreur est définie
    }

    // Continue avec la logique de soumission si la validation passe
    setSubmissionData({ type: 'arbitrator-submit' });
    setValue('submissionType', 'arbitrator-submit');
    setIsConfirmationOpen(true);
  };

  const handleSupervisorSubmit = () => {
    setSubmissionData({ type: 'supervisor-submit' });
    setValue('submissionType', 'supervisor-submit');
    setIsConfirmationOpen(true);
  };

  // Fonction appelée lors de la confirmation dans la modale
  const handleConfirmSubmission = async () => {
    setIsConfirmationOpen(false);
    if (formSubmissionData) {
      // Appeler la fonction onSubmit avec les données du formulaire
      await onSubmit(formSubmissionData);
    }
  };

  const handleFormSubmit = (data: any) => {
    // Enregistrer les données du formulaire dans l'état
    setFormSubmissionData({
      ...data,
      pipedrive_deal_id: dealId,
      last_user: currentUser?.email,
      last_user_role: currentUser?.data.data.role,
    });
    // Ouvrir la modale de confirmation
    setIsConfirmationOpen(true);
  };

  const handleSendAgencyArbitrationEmail = async () => {
    const prices = {
      depositSaleArbitrator,
      straightTradeInArbitrator,
      thirtyDaySaleMandateArbitrator,
      depositSaleSupervisor,
      straightTradeInSupervisor,
      thirtyDaySaleMandateSupervisor,
    };

    try {
      const sendAgencyArbitrationEmail = await makeAuthenticatedApiCall('post', '/api/mails/arbitration-agency', {
        dealData,
        arbitrationDealData: arbitrationDealInfo?.data,
        prices,
      });
      if (sendAgencyArbitrationEmail.status === 200) {
        // console.log(sendAgencyArbitrationEmail);
      } else {
        // la logique avec handleApiError et la snackbar
      }
    } catch (error) {
      console.log(error);
      // la logique avec handleApiError et la snackbar
    }
  };
  const onSubmit = async (data: any) => {
    data.pipedrive_deal_id = dealId;
    data.last_user = currentUser?.email;
    data.last_user_role = currentUser?.data.data.role;

    try {
      const response = await makeAuthenticatedApiCall('post', '/api/arbitration', data);
      // Afficher un message de succès si la requête est réussie
      if (response.status === 200) {
        console.log('response', response);
        const data = response.data as { arbitrationStatus: string }; // Type assertion
        if (data.arbitrationStatus === 'awaiting_supervision') {
          // Envoyer un e-mail de notification de supervision

          if (dealData && dealData?.data?.registration_plate) {
            const sendSupervisorEmail = await makeAuthenticatedApiCall('post', '/api/mails/arbitration-supervisor', {
              dealId: dealId,
              extUrl: `${extUrl}&arbitrator`,
              regPlate: regPlate,
            });

            if (sendSupervisorEmail.status === 200) {
              setSuccessMessage('Supervision demandée avec succès');
            } else {
              // toute la logique avec handleApiError et SnackBar
            }
          }
        }
        if (data.arbitrationStatus === 'processed') {
          // Envoyer un e-mail de notification à l'agence
          handleSendAgencyArbitrationEmail();
          setArbitratorInputsDisabled(true);
          setSuccessMessage('Votre arbitrage a bien été pris en compte');

          queryClient.invalidateQueries({ queryKey: ['useArbitratorDeal'] });
          queryClient.invalidateQueries({ queryKey: ['useArbitratorDeals'] });
          queryClient.invalidateQueries({ queryKey: ['useDatabaseDeals'] });
        }
        if (data.submissionType === 'supervisor-submit') {
          // Envoyer un e-mail de notification à l'agence
          handleSendAgencyArbitrationEmail();
          setSuccessMessage('Votre supervision a bien été pris en compte');
          setSupervisorInputsDisabled(true);

          queryClient.invalidateQueries({ queryKey: ['useArbitratorDeals'] });
          queryClient.invalidateQueries({ queryKey: ['useDatabaseDeals'] });
        }

        // setArbitratorInputsDisabled(true); // On disable PAS car en fait on doit pouvoir encore modif les champs
        // Logique pour gérer la réponse réussie
        console.log('Arbitrage enregistré avec succès:', response.data.message);
        // Par exemple, vous pouvez définir un état pour afficher une notification ou rediriger l'utilisateur
      } else {
        // Gérer les réponses autres que 200 OK
        console.error('Réponse du serveur:', response.data.message, response);
        // Afficher un message d'erreur ou effectuer d'autres actions en cas d'échec
      }
    } catch (error) {
      // Gérer les erreurs réseau ou les erreurs renvoyées par makeAuthenticatedApiCall
      console.error("Erreur lors de l'appel API:", error);
      // Vous pouvez afficher un message d'erreur à l'utilisateur
    } finally {
      refetchArbitratorDeals();
      refetchUseDatabaseDeals();
    }
  };

  useEffect(() => {
    // Initialiser les valeurs du formulaire avec les données de l'arbitrage
    if (arbitrationDealInfo?.data) {
      const fields = [
        'deposit_sale_arbitrator',
        'straight_trade_in_arbitrator',
        'thirty_day_sale_mandate_arbitrator',
        'market_price_arbitrator',
        'arbitrator_comment',
        'arbitrator_lbc',
        'arbitrator_indicata',
        'deposit_sale_supervisor',
        'straight_trade_in_supervisor',
        'thirty_day_sale_mandate_supervisor',
        'market_price_supervisor',
        'supervisor_comment',
        'supervisor_lbc',
        'supervisor_indicata',
      ];
      fields.forEach((field) => {
        setValue(field, arbitrationDealInfo.data[field]);
      });
    }
    // Gestion de la désactivation des champs
    const status = arbitrationDealInfo?.data?.status;

    if (status === 'to_be_processed' || status === undefined) {
      if (currentUser?.data.data.role === 'arbitrator') {
        setArbitratorInputsDisabled(false);
        setSupervisorInputsDisabled(true);
      } else {
        setArbitratorInputsDisabled(true);
        setSupervisorInputsDisabled(false);
      }
    } else if (status === 'awaiting_supervision') {
      if (currentUser?.data.data.role === 'arbitrator') {
        setArbitratorInputsDisabled(false);
        setSupervisorInputsDisabled(true);
      } else {
        setArbitratorInputsDisabled(true);
      }
    } else if (status === 'processed') {
      setSupervisorInputsDisabled(true);
      setArbitratorInputsDisabled(true);
    }

    setIsLoading(false);
  }, [arbitrationDealInfo, setValue]);

  const isArbitratorSubmitDisabled = useMemo(() => {
    const formatValue = (value: any) => {
      // Convertir la valeur en chaîne et enlever les espaces
      return typeof value === 'string' ? parseInt(value.replace(/\s+/g, '') || '0') : 0;
    };

    return (
      (formatValue(depositSaleArbitrator) >= 20000 ||
        formatValue(straightTradeInArbitrator) >= 20000 ||
        formatValue(thirtyDaySaleMandateArbitrator) >= 20000) &&
      currentUser?.data.data.role !== 'god'
    );
  }, [depositSaleArbitrator, straightTradeInArbitrator, thirtyDaySaleMandateArbitrator, currentUser]);
  // Si je suis dieu / superviseur, je peux directement balancer les chiffres que je veux

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\s+/g, '');
    const numericValue = value.replace(/[^0-9]/g, '');
    const formattedValue = numericValue ? numberFormatter.format(parseInt(numericValue)) : '';
    setValue(event.target.name, formattedValue);
  };

  // Composant de modale de confirmation
  const renderConfirmationDialog = () => (
    <Dialog open={isConfirmationOpen} onClose={() => setIsConfirmationOpen(false)}>
      <DialogTitle>Confirmer la soumission</DialogTitle>
      <DialogContent>
        <DialogContentText>Êtes-vous sûr de vouloir soumettre ces informations ?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsConfirmationOpen(false)}>Annuler</Button>
        <Button onClick={handleConfirmSubmission} autoFocus>
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      <Divider
        variant="fullWidth"
        component="hr"
        style={{
          marginLeft: 24,
          backgroundColor: '#fdd15f',
          height: '2px',
          width: 'calc(100% - 24px)',
          border: 0,
          marginTop: 15,
        }}
      />
      <Grid container item xs={12} md={12}>
        <>
          <Box sx={{ width: '100%' }}>
            <Tabs
              value={tabValue}
              sx={{ marginTop: 0 }}
              orientation={isMobile ? 'vertical' : 'horizontal'}
              onChange={handleChange}
              className="DealsListTabs"
              centered
            >
              <Tab label="Arbitrage" />
              <Tab label="Achat" />
              <Tab label="Mandat" />
              <Tab label="Depot-Vente" />
            </Tabs>

            <Box
              role="tabpanel"
              hidden={tabValue !== 0}
              id={`tabpanel-arbitrage`}
              aria-labelledby={`tab-arbitrage`}
              style={{ width: '100%' }}
            >
              <form className="formArbitration" onSubmit={handleSubmit(handleFormSubmit)}>
                <Grid container spacing={6}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h5" sx={{ marginTop: 2, marginBottom: 2 }}>
                      Arbitrage
                    </Typography>

                    <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                      <TextField
                        {...register('thirty_day_sale_mandate_arbitrator')}
                        id="thirty_day_sale_mandate_arbitrator-label"
                        fullWidth
                        label="Mandat 30 jours"
                        disabled={arbitratorInputsDisabled}
                        className="inputElement"
                        value={thirtyDaySaleMandateArbitrator || ''}
                        onChange={handleNumberChange}
                        onBlur={() => trigger('thirty_day_sale_mandate_arbitrator')}
                        InputProps={{
                          endAdornment: (
                            <Typography variant="body2" style={{ fontWeight: 'normal' }}>
                              €
                            </Typography>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                      <TextField
                        {...register('straight_trade_in_arbitrator')}
                        id="straight_trade_in_arbitrator-label"
                        fullWidth
                        disabled={arbitratorInputsDisabled}
                        label="Reprise sèche"
                        className="inputElement"
                        value={straightTradeInArbitrator || ''}
                        onChange={handleNumberChange}
                        onBlur={() => trigger('straight_trade_in_arbitrator')}
                        InputProps={{
                          endAdornment: (
                            <Typography variant="body2" style={{ fontWeight: 'normal' }}>
                              €
                            </Typography>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                      <TextField
                        {...register('deposit_sale_arbitrator')}
                        id="deposit_sale_arbitrator-label"
                        type="text"
                        fullWidth
                        disabled={arbitratorInputsDisabled}
                        label="Dépôt-Vente"
                        className="inputElement"
                        value={depositSaleArbitrator || ''}
                        onChange={handleNumberChange}
                        onBlur={() => trigger('deposit_sale_arbitrator')}
                        InputProps={{
                          endAdornment: (
                            <Typography variant="body2" style={{ fontWeight: 'normal' }}>
                              €
                            </Typography>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                      <TextField
                        {...register('market_price_arbitrator', {
                          required: currentUser?.data.data.role === 'god' ? false : 'Ce champ est requis',
                        })}
                        error={!!errors.market_price_arbitrator}
                        helperText={getErrorMessage(errors.market_price_arbitrator) || ''}
                        id="market_price_arbitrator-label"
                        fullWidth
                        label="Prix du marché"
                        disabled={arbitratorInputsDisabled}
                        className="inputElement"
                        value={MarketPriceArbitrator || ''}
                        onChange={handleNumberChange}
                        onBlur={() => trigger('market_price_arbitrator')}
                        InputProps={{
                          endAdornment: (
                            <Typography variant="body2" style={{ fontWeight: 'normal' }}>
                              €
                            </Typography>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h5" sx={{ marginTop: 2, marginBottom: 2 }}>
                      Arbitrage par superviseur
                    </Typography>

                    <>
                      <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                        <TextField
                          {...register('thirty_day_sale_mandate_supervisor')}
                          id="thirty_day_sale_mandate_supervisor-label"
                          fullWidth
                          label="Mandat 30 jours"
                          disabled={supervisorInputsDisabled}
                          className="inputElement"
                          value={thirtyDaySaleMandateSupervisor || ''}
                          onChange={handleNumberChange}
                          onBlur={() => trigger('thirty_day_sale_mandate_supervisor')}
                          InputProps={{
                            endAdornment: (
                              <Typography variant="body2" style={{ fontWeight: 'normal' }}>
                                €
                              </Typography>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                        <TextField
                          {...register('straight_trade_in_supervisor')}
                          id="straight_trade_in_supervisor-label"
                          fullWidth
                          disabled={supervisorInputsDisabled}
                          label="Reprise sèche"
                          className="inputElement"
                          value={straightTradeInSupervisor || ''}
                          onChange={handleNumberChange}
                          onBlur={() => trigger('straight_trade_in_supervisor')}
                          InputProps={{
                            endAdornment: (
                              <Typography variant="body2" style={{ fontWeight: 'normal' }}>
                                €
                              </Typography>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                        <TextField
                          {...register('deposit_sale_supervisor')}
                          id="deposit_sale_supervisor-label"
                          type="text"
                          fullWidth
                          disabled={supervisorInputsDisabled}
                          label="Dépôt-Vente"
                          className="inputElement"
                          value={depositSaleSupervisor || ''}
                          onChange={handleNumberChange}
                          onBlur={() => trigger('deposit_sale_supervisor')}
                          InputProps={{
                            endAdornment: (
                              <Typography variant="body2" style={{ fontWeight: 'normal' }}>
                                €
                              </Typography>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                        <TextField
                          {...register('market_price_supervisor')}
                          id="market_price_supervisor-label"
                          type="text"
                          fullWidth
                          disabled={supervisorInputsDisabled}
                          label="Prix du marché"
                          className="inputElement"
                          value={MarketPriceSupervisor || ''}
                          onChange={handleNumberChange}
                          onBlur={() => trigger('market_price_supervisor')}
                          InputProps={{
                            endAdornment: (
                              <Typography variant="body2" style={{ fontWeight: 'normal' }}>
                                €
                              </Typography>
                            ),
                          }}
                        />
                      </Grid>
                    </>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      {...register('arbitrator_comment')}
                      disabled={arbitratorInputsDisabled}
                      onBlur={() => trigger('arbitrator_comment')}
                      value={commentArbitrator || ''}
                      fullWidth
                      label="Commentaire Arbitre"
                      multiline
                      rows={5}
                      placeholder=""
                      className="inputElement"
                    />
                    {arbitratorInputsDisabled && lbcArbitrator ? (
                      <>
                        <Link
                          sx={{ display: 'block', marginTop: 5 }}
                          variant="body2"
                          target="_blank"
                          href={lbcArbitrator}
                        >
                          Lien LBC
                        </Link>
                      </>
                    ) : (
                      <>
                        <TextField
                          {...register('arbitrator_lbc')}
                          id="lbc-label"
                          fullWidth
                          disabled={arbitratorInputsDisabled}
                          value={lbcArbitrator || ''}
                          onBlur={() => trigger('arbitrator_lbc')}
                          label="Recherche LBC (Arbitre)"
                          className="inputElement"
                          sx={{ marginTop: 2, width: '100%' }}
                        />
                      </>
                    )}
                    {arbitratorInputsDisabled && indicataArbitrator ? (
                      <>
                        <Link
                          sx={{ display: 'block', marginTop: 5 }}
                          variant="body2"
                          target="_blank"
                          href={indicataArbitrator}
                        >
                          Lien Indicata
                        </Link>
                      </>
                    ) : (
                      <>
                        <TextField
                          {...register('arbitrator_indicata')}
                          id="indicata-label"
                          fullWidth
                          disabled={arbitratorInputsDisabled}
                          value={indicataArbitrator || ''}
                          onBlur={() => trigger('arbitrator_indicata')}
                          label="Recherche Indicata (Arbitre)"
                          className="inputElement"
                          sx={{ marginTop: 2, width: '100%' }}
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      {...register('supervisor_comment')}
                      disabled={supervisorInputsDisabled}
                      onBlur={() => trigger('supervisor_comment')}
                      value={commentSupervisor || ''}
                      fullWidth
                      label="Commentaire Superviseur"
                      multiline
                      rows={5}
                      placeholder=""
                      className="inputElement"
                    />
                    {supervisorInputsDisabled && lbcSupervisor ? (
                      <>
                        <Link
                          sx={{ display: 'block', marginTop: 5 }}
                          variant="body2"
                          target="_blank"
                          href={lbcSupervisor}
                        >
                          Lien LBC
                        </Link>
                      </>
                    ) : (
                      <>
                        <TextField
                          {...register('supervisor_lbc')}
                          id="lbc-label"
                          fullWidth
                          disabled={supervisorInputsDisabled}
                          value={lbcSupervisor || ''}
                          onBlur={() => trigger('supervisor_lbc')}
                          label="Recherche LBC (Superviseur)"
                          className="inputElement"
                          sx={{ marginTop: 2, width: '100%' }}
                        />
                      </>
                    )}
                    {supervisorInputsDisabled && indicataSupervisor ? (
                      <>
                        <Link
                          sx={{ display: 'block', marginTop: 5 }}
                          variant="body2"
                          target="_blank"
                          href={indicataSupervisor}
                        >
                          Lien Indicata
                        </Link>
                      </>
                    ) : (
                      <>
                        <TextField
                          {...register('supervisor_indicata')}
                          id="indicata-label"
                          fullWidth
                          disabled={supervisorInputsDisabled}
                          value={indicataSupervisor || ''}
                          onBlur={() => trigger('supervisor_indicata')}
                          label="Recherche Indicata (Superviseur)"
                          className="inputElement"
                          sx={{ marginTop: 2, width: '100%' }}
                        />
                      </>
                    )}
                  </Grid>

                  <Grid item mb={4} xs={12} md={6} display={'flex'} flexDirection={'column'}>
                    <Tooltip
                      title={
                        isArbitratorSubmitDisabled
                          ? "Cet arbitrage doit être vérifié par un Superviseur, veuillez utiliser le bouton 'Supervision'"
                          : ''
                      }
                      placement="top"
                      sx={{
                        typography: 'body2', // Utilisez une clé de typographie pour définir la taille de la police
                        '& .MuiTooltip-tooltip': {
                          fontSize: '16px', // Taille de la police personnalisée
                          textAlign: 'center', // Alignement du texte
                        },
                      }}
                    >
                      <span>
                        <Button
                          type="submit"
                          id="arbitrator-submit"
                          variant="contained"
                          disabled={isArbitratorSubmitDisabled || arbitratorInputsDisabled}
                          onClick={handleArbitratorSubmit}
                          sx={{
                            width: 250,
                            marginRight: 0,
                            marginBottom: 1,
                            fontWeight: 600,
                            backgroundColor: theme.palette.saffron.main,
                            '&:hover': { backgroundColor: theme.palette.saffron.main },
                          }}
                        >
                          Soumettre
                        </Button>
                      </span>
                    </Tooltip>
                    <Button
                      type="submit"
                      id="arbitrator-asking-supervisor"
                      disabled={arbitratorInputsDisabled || currentUser?.data.data.role === 'god'}
                      variant="contained"
                      onClick={() => setValue('submissionType', 'arbitrator-asking-supervisor')}
                      sx={{
                        width: 250,
                        marginRight: 0,
                        fontWeight: 600,
                        backgroundColor: theme.palette.saffron.main,
                        '&:hover': { backgroundColor: theme.palette.saffron.main },
                      }}
                    >
                      Supervision
                    </Button>
                    <Grid item xs={12} md={12}>
                      <Typography>{successMessage}</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    mb={4}
                    xs={12}
                    md={6}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'flex-end'}
                    justifyContent={'flex-end'}
                  >
                    <Button
                      type="submit"
                      id="supervisor-submit"
                      variant="contained"
                      onClick={handleSupervisorSubmit}
                      disabled={arbitrationDealInfo?.data?.status === 'processed' || supervisorInputsDisabled}
                      sx={{
                        width: 250,
                        marginRight: 0,
                        fontWeight: 600,
                        backgroundColor: theme.palette.saffron.main,
                        '&:hover': { backgroundColor: theme.palette.saffron.main },
                      }}
                    >
                      Soumettre
                    </Button>
                  </Grid>
                  {arbitrationDealInfo?.data?.last_update && (
                    <Grid sx={{ paddingTop: '0 !important' }} item xs={12} md={6}>
                      <Typography variant="body2">
                        Dernière mise à jour :{' '}
                        {formatSimpleDateToDDMMYYYYWithTime(arbitrationDealInfo?.data?.last_update)}
                        {' par '}
                        {arbitrationDealInfo?.data?.last_user}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </form>

              {renderConfirmationDialog()}
            </Box>
            <Box
              role="tabpanel"
              hidden={tabValue !== 1}
              id={`tabpanel-purchase-calculator`}
              aria-labelledby={`tab-purchase-calculator`}
              style={{ width: '100%' }}
            >
              {tabValue === 1 && <PurchaseCalculator dealId={dealId} />}
            </Box>
            <Box
              role="tabpanel"
              hidden={tabValue !== 2}
              id={`tabpanel-mandate-calculator`}
              aria-labelledby={`tab-mandate-calculator`}
              style={{ width: '100%' }}
            >
              {tabValue === 2 && (
                <MandateCalculator dealId={dealId} purchasePriceDB={formatPriceToNumber(purchasePrice)} />
              )}
            </Box>
            <Box
              role="tabpanel"
              hidden={tabValue !== 3}
              id={`tabpanel-deposit_sale-calculator`}
              aria-labelledby={`tab-deposit_sale-calculator`}
              style={{ width: '100%' }}
            >
              {tabValue === 3 &&
                (isLoading ? (
                  <CircularProgress />
                ) : (
                  <DepositSaleCalculator
                    dealId={dealId}
                    orgId={orgId}
                    model={model ?? ''}
                    extUrl={`${extUrl}&arbitrator`}
                    regPlate={regPlate}
                    marketPriceDb={formatPriceToNumber(MarketPriceSupervisor ?? MarketPriceArbitrator ?? '0')}
                    purchasePriceDb={formatPriceToNumber(
                      depositSaleSupervisor && depositSaleSupervisor !== 0
                        ? depositSaleSupervisor
                        : depositSaleArbitrator && depositSaleArbitrator !== 0
                          ? depositSaleArbitrator
                          : purchasePrice,
                    )}
                  />
                ))}
            </Box>
          </Box>
        </>
      </Grid>
    </>
  );
};

export default CaseArbitration;

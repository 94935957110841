import { useState } from 'react';
import {
  CircularProgress,
  Button,
  TextField,
  Avatar,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import { useTranslation } from 'react-i18next';
import theme from '../theme';

const AuthPage = () => {
  const { t } = useTranslation('common');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

  const auth = getAuth();

  const handleLogin = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setLoading(true);

    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setLoading(false);
        // Redirect to the intended path or default to home
        const redirectTo = location.state?.from || '/'; // Use the stored path
        navigate(redirectTo);
      })
      .catch((error) => {
        setLoading(false);
        setErrorMessage(`${t(error.code)}`);
      });
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: theme.palette.saffron.main }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('Sign in')}
          </Typography>
          <Box component="form" noValidate onSubmit={handleLogin} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              className="inputElement"
              required
              fullWidth
              id="email"
              label={t('Email address')}
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              className="inputElement"
              name="password"
              label={t('Password')}
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControlLabel
              className="inputElement"
              control={<Checkbox value="remember" color="primary" />}
              label={t('Remember me')}
            />
            <Button
              fullWidth
              sx={{
                marginTop: 5,
                marginBottom: 2,
                backgroundColor: theme.palette.saffron.main,
                '&:hover': { backgroundColor: theme.palette.saffron.main },
              }}
              type="submit"
              variant="contained"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : t('Sign in')}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link
                  href="/password-reset"
                  variant="body2"
                  sx={{ textDecorationColor: 'inherit', color: theme.palette.saffron.main }}
                >
                  {t('Forgot password?')}
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="https://sgipda4svdm.typeform.com/Welcome2SKIPCAR"
                  target="_blank"
                  variant="body2"
                  sx={{ textDecorationColor: 'inherit', color: theme.palette.saffron.main }}
                >
                  {t("Don't have an account? Sign up")}
                </Link>
              </Grid>
            </Grid>
            {errorMessage && (
              <Typography sx={{ marginTop: 2, color: 'red !important' }} variant="body1" align="center">
                {errorMessage}
              </Typography>
            )}
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default AuthPage;

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { makeAuthenticatedApiCall } from '../../apiHelper';
import { formatDateToDDMMYYYY } from '../../utils';

const stickyCellStyle = {
  background: '#262626',
  fontWeight: 'bold',
  minWidth: 50,
  color: 'white',
  position: 'sticky',
  top: 0,
  zIndex: 100,
};

interface AgencyInfoDialogProps {
  clientId: string | null;
  open: boolean;
  onClose: () => void;
}

const AgencyInfoDialog: React.FC<AgencyInfoDialogProps> = ({ clientId, open, onClose }) => {
  const [clientData, setClientData] = useState<any>(null);
  const [clientInvoices, setClientInvoices] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (clientId) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const responseClient = await makeAuthenticatedApiCall('get', `/api/qonto/client-information/${clientId}`);
          setClientData(responseClient.data);
        } catch (error) {
          console.error('Error fetching client data:', error);
        }

        try {
          const responseInvoices = await makeAuthenticatedApiCall('get', `/api/qonto/deals-qonto/${clientId}`);
          setClientInvoices(responseInvoices.data);
        } catch (error) {
          console.error('Error fetching client invoices:', error);
        }
        setIsLoading(false);
      };

      fetchData();
    }
  }, [clientId]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle align="center" fontWeight="bold">
        Informations de l'agence <span style={{ textDecoration: 'underline' }}>{clientData?.name}</span>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {clientData && (
              <Box mb={2}>
                <Typography variant="body2" component="p">
                  <span style={{ textDecoration: 'underline' }}>Société : </span>
                  {clientData.name}
                </Typography>
                <Typography variant="body2" component="p">
                  <span style={{ textDecoration: 'underline' }}>Nom:</span> {clientData.first_name}{' '}
                  {clientData.last_name}
                </Typography>
                <Typography variant="body2" component="p">
                  <span style={{ textDecoration: 'underline' }}>Email:</span> {clientData.email}
                </Typography>
                <Typography variant="body2" component="p">
                  <span style={{ textDecoration: 'underline' }}>Numéro d'identification fiscale:</span>{' '}
                  {clientData.tax_identification_number}
                </Typography>
                <Typography variant="body2" component="p">
                  <span style={{ textDecoration: 'underline' }}>Adresse:</span> {clientData.address}, {clientData.city},{' '}
                  {clientData.zip_code}, {clientData.country_code}
                </Typography>
                <Typography variant="body2" component="p">
                  <span style={{ textDecoration: 'underline' }}>Date de création:</span>{' '}
                  {new Date(clientData.created_at).toLocaleDateString()}
                </Typography>
                <Typography variant="body2" component="p">
                  <span style={{ textDecoration: 'underline' }}>Adresse de facturation:</span>{' '}
                  {clientData.billing_address.street_address}, {clientData.billing_address.city},{' '}
                  {clientData.billing_address.zip_code}, {clientData.billing_address.country_code}
                </Typography>
              </Box>
            )}
            {clientInvoices && (
              <TableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={stickyCellStyle}>Numéro de facture</TableCell>
                      <TableCell sx={stickyCellStyle}>Statut</TableCell>
                      <TableCell sx={stickyCellStyle}>Date</TableCell>
                      <TableCell sx={stickyCellStyle}>Lien vers la facture</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {clientInvoices.map((invoice: any) => (
                      <TableRow key={invoice.invoice_id}>
                        <TableCell>{invoice.invoice_number}</TableCell>
                        <TableCell>{invoice.invoice_status}</TableCell>
                        <TableCell>{formatDateToDDMMYYYY(invoice.last_update)}</TableCell>
                        <TableCell>
                          <a
                            href={invoice.invoice_url_firebase}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: 'black', fontWeight: 600 }}
                          >
                            Voir la facture
                          </a>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AgencyInfoDialog;

import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useLocation } from 'react-router-dom';

import {
  AlertColor,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { makeAuthenticatedApiCall } from '../apiHelper';
import { useCGData } from '../hooks/useApiQueries';
import theme from '../theme';
import { AgencyData } from '../types/cg';
import {
  convertToLocalDateTimeString,
  convertToLocalTime,
  formatDateToISO,
  formatUTCDateTime,
  removeSpaces,
} from '../utils';
import { useAuth } from './AuthContext';
import AdvantagesModal from './CGForm/AdvantagesModal';
import FilesOpener from './CGForm/FilesOpener';
import TaxModal from './CGForm/TaxModal';
import FirebaseUploadComponent from './CreateCotation/FirebaseUploadComponent';
import { ErrorMessageSnackbar } from './ErrorMessageSnackbar';

const CGForm = () => {
  const { currentUser } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState<AlertColor>('error');

  const [purchaseDateTime, setPurchaseDateTime] = useState('');

  const [taxData, setTaxData] = useState(null);
  const [agencyData, setAgencyData] = useState<AgencyData | null>(null);
  const [registrationId, setRegistrationId] = useState<number | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const [birthDepartmentOptions, setBirthDepartmentOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedPostalCode, setSelectedPostalCode] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const registrationIdGetParameter = searchParams.get('registrationId');

  const { data: cgData, isLoading: isLoadingCGData } = registrationIdGetParameter
    ? useCGData(registrationIdGetParameter ?? '')
    : { data: null, isLoading: false, error: null };

  const [sellerFilesUrls, setSellerFilesUrls] = useState<string[]>(
    cgData?.sellerFilesUrls ? cgData.sellerFilesUrls.split(',').map((url: string) => url.trim()) : [],
  );

  const [buyerFilesUrls, setBuyerFilesUrls] = useState<string[]>(
    cgData?.buyerFilesUrls.split(',').map((url: string) => url.trim()) ?? [],
  );
  const [buyerResidencyFilesUrls, setBuyerResidencyFilesUrls] = useState<string[]>(
    cgData?.buyerResidencyFilesUrls.split(',').map((url: string) => url.trim()) ?? [],
  );
  const [coBuyerFilesUrls, setCoBuyerFilesUrls] = useState<string[]>(
    cgData?.coBuyerFilesUrls.split(',').map((url: string) => url.trim()) ?? [],
  );

  const isReadOnly = registrationIdGetParameter != null;

  const openSnackbar = (message: string, severity: AlertColor) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setSnackbarOpen(true);
  };

  const formMethods = useForm({
    mode: 'all',
    defaultValues: {
      // Identifiants et informations de base du véhicule
      regPlate: '',
      vin: '',
      numFormule: '',
      purchaseDateTime: '',

      // Informations sur les fichiers
      sellerFilesUrls: [],
      buyerFilesUrls: [],
      buyerResidencyFilesUrls: [],
      coBuyerFilesUrls: [],

      // Détails de l'ancien propriétaire
      sellerClientType: 'person',
      sellerGender: cgData?.sellerGender ?? '',
      sellerFirstName: '',
      sellerLastName: '',
      sellerUsualName: '',
      sellerSiret: '',
      sellerCompanyName: '',

      // Détails du nouveau propriétaire
      buyerClientType: 'person',
      buyerGender: cgData?.buyerGender ?? '',
      buyerFirstName: '',
      buyerLastName: '',
      buyerUsualName: '',
      buyerSiret: '',
      buyerCompanyName: '',

      // Adresse du nouveau propriétaire
      buyerAddressNumber: '',
      buyerAddressExtension: '',
      buyerAddressType: '',
      buyerAddressName: '',
      buyerAddressPlace: '',
      buyerAddressStairs: '',
      buyerAddressComp: '',
      buyerAddressPostalBox: '',
      buyerCity: '',
      buyerPostalCode: '',
      buyerCountry: '',

      // Informations supplémentaires pour les particuliers
      buyerResidenceType: 'owner',
      buyerDateOfBirth: '',
      buyerPlaceOfBirth: '',
      buyerCountryOfBirth: 'FRANCE',
      buyerDepartmentOfBirth: '',
      buyerPhone: '',
      buyerDocumentType: '', // Initialisé sans sélection car le type de document est conditionnel

      // Informations sur le co-titulaire
      cobuyer: 'no',
      coBuyerClientType: 'person',
      coBuyerGender: cgData?.coBuyerGender ?? '',
      coBuyerFirstName: '',
      coBuyerLastName: '',
      coBuyerUsualName: '',
      coBuyerCompanyName: '',
      coBuyerSiret: '',
    },
  });

  const { control, formState, reset, trigger, watch, setValue, setError, clearErrors } = formMethods;
  const { errors, isValid } = formState;

  const sellerClientType = watch('sellerClientType');
  const buyerClientType = watch('buyerClientType');
  const buyerResidenceType = watch('buyerResidenceType');
  const cobuyer = watch('cobuyer');
  const coBuyerClientType = watch('coBuyerClientType');
  const regPlateValue = watch('regPlate');
  const vinValue = watch('vin');
  const numFormuleValue = watch('numFormule');
  const buyerPostalCode = watch('buyerPostalCode');
  const buyerCountryOfBirth = watch('buyerCountryOfBirth');
  const isFrance = buyerCountryOfBirth === 'FRANCE';
  const buyerDepartmentValue = watch('buyerDepartmentOfBirth');

  const validationRules = {
    regPlate: {
      required: 'Ce champ est requis',
      pattern: {
        value: /^(?:[A-Z]{2}-\d{3}-[A-Z]{2})|(?:\d{3} [A-Z]{3} \d{2})$/,
        message:
          "Le format de la plaque n'est pas valide. S'il s'agit d'une ancienne plaque, veuillez nous envoyer le dossier à <a style='color:black' href='mailto:skipcarcg@gmail.com' target='_blank'>skipcarcg@gmail.com</a>",
      },
      minLength: {
        value: 7,
        message: "L'immatriculation doit contenir au moins 7 caractères hors tirets et espaces",
      },
      validate: (value: string) => {
        const cleanedValue = value.replace(/[-\s]/g, ''); // Enlève les tirets et les espaces
        return (
          cleanedValue.length >= 7 || "L'immatriculation doit contenir au moins 7 caractères hors tirets et espaces"
        );
      },
    },
    vin: { required: 'Ce champ est requis' },
    numFormule: { required: 'Ce champ est requis' },

    sellerFirstName: { required: 'Ce champ est requis' },
    sellerLastName: { required: 'Ce champ est requis' },
    buyerFirstName: { required: 'Ce champ est requis' },
    buyerLastName: { required: 'Ce champ est requis' },
    coBuyerFirstName: { required: 'Ce champ est requis' },
    coBuyerLastName: { required: 'Ce champ est requis' },
    sellerGender: { required: 'Ce champ est requis' },
    buyerGender: { required: 'Ce champ est requis' },
    coBuyerGender: { required: 'Ce champ est requis' },

    addressNumber: {
      validate: (value: string) => {
        const addressType = watch('buyerAddressType');
        if (addressType !== 'ldit' && !value) {
          return 'Ce champ est requis';
        }
        return true;
      },
    },
    addressType: { required: 'Ce champ est requis' },
    addressName: { required: 'Ce champ est requis' },
    city: { required: 'Ce champ est requis' },
    postalCode: { required: 'Ce champ est requis' },
    country: { required: 'Ce champ est requis' },

    sellerSiret: { required: 'Ce champ est requis' },
    sellerCompanyName: { required: 'Ce champ est requis' },
    buyerSiret: { required: 'Ce champ est requis' },
    buyerCompanyName: { required: 'Ce champ est requis' },
    coBuyerSiret: { required: 'Ce champ est requis' },
    coBuyerCompanyName: { required: 'Ce champ est requis' },

    buyerPlaceOfBirth: { required: 'Ce champ est requis' },
  };

  const sellerFilesUploadComplete = (url: string) => {
    setSellerFilesUrls((updatedUrls) => {
      const newUrls = [...updatedUrls, url];
      // Effacer l'erreur si des fichiers existent
      if (newUrls.length > 0) {
        clearErrors('sellerFilesUrls');
      }
      return newUrls;
    });
  };

  const sellerFilesDeleteComplete = (url: string) => {
    const updatedUrls = sellerFilesUrls.filter((u) => u !== url);
    setSellerFilesUrls(updatedUrls);
    // Afficher l'erreur si aucun fichier n'est présent
    if (updatedUrls.length < 1) {
      setError('sellerFilesUrls', {
        type: 'manual',
        message: 'Veuillez au moins envoyer un fichier',
      });
    }
  };

  const buyerFilesUploadComplete = (url: string) => {
    setBuyerFilesUrls((updatedUrls) => [...updatedUrls, url]);
  };

  const buyerFilesDeleteComplete = (url: string) => {
    const updatedUrls = buyerFilesUrls.filter((u) => u !== url);
    setBuyerFilesUrls(updatedUrls);
  };

  const coBuyerFilesUploadComplete = (url: string) => {
    setCoBuyerFilesUrls((updatedUrls) => [...updatedUrls, url]);
  };

  const coBuyerFilesDeleteComplete = (url: string) => {
    const updatedUrls = coBuyerFilesUrls.filter((u) => u !== url);
    setCoBuyerFilesUrls(updatedUrls);
  };

  const buyerResidencyFilesUploadComplete = async (url: string) => {
    setBuyerResidencyFilesUrls((updatedUrls) => [...updatedUrls, url]);
  };

  const buyerResidencyFilesDeleteComplete = (url: string) => {
    const updatedUrls = buyerFilesUrls.filter((u) => u !== url);
    setBuyerResidencyFilesUrls(updatedUrls);
  };

  useEffect(() => {
    if (cgData) {
      setBuyerFilesUrls(cgData?.buyerFilesUrls.split(',').map((url: string) => url.trim()) ?? []);
      setBuyerResidencyFilesUrls(cgData?.buyerResidencyFilesUrls.split(',').map((url: string) => url.trim()) ?? []);
      setCoBuyerFilesUrls(cgData?.coBuyerFilesUrls.split(',').map((url: string) => url.trim()) ?? []);
      setSellerFilesUrls(cgData?.sellerFilesUrls.split(',').map((url: string) => url.trim()) ?? []);
    }
  }, [cgData]);

  const handleConfirmClick = async () => {
    // Déclenche la validation du formulaire
    const formValid = await trigger();

    // Vérifie également l'absence d'erreurs manuelles sur les champs de fichiers
    const noFileErrors =
      !errors.sellerFilesUrls &&
      !errors.buyerFilesUrls &&
      !errors.buyerResidencyFilesUrls &&
      !(cobuyer === 'yes' && errors.coBuyerFilesUrls);

    // Ouvre la modale de confirmation si le formulaire est valide et qu'il n'y a pas d'erreurs sur les fichiers
    if (formValid && noFileErrors) {
      setIsConfirmModalOpen(true);
    } else {
      // Optionnel : afficher un message d'erreur ou effectuer une autre action pour indiquer le problème
      openSnackbar('Des informations sont manquantes ou incorrectes. Veuillez vérifier le formulaire.', 'error');
    }
  };

  useEffect(() => {
    if (buyerCountryOfBirth === 'FRANCE') {
      trigger('buyerDepartmentOfBirth'); // Déclenchez la revalidation du champ si le pays est la France
    }
  }, [buyerCountryOfBirth, trigger]);

  useEffect(() => {
    // Validation pour 'sellerFilesUrls'
    if (sellerFilesUrls.length < 1) {
      setError('sellerFilesUrls', {
        type: 'manual',
        message: 'Veuillez au moins envoyer un fichier',
      });
    } else {
      clearErrors('sellerFilesUrls');
    }

    // Validation pour 'gender'
    if (!watch('sellerGender')) {
      setError('sellerGender', {
        type: 'manual',
        message: 'Ce champ est requis',
      });
    } else {
      clearErrors('sellerGender');
    }

    if (!watch('buyerGender')) {
      setError('buyerGender', {
        type: 'manual',
        message: 'Ce champ est requis',
      });
    } else {
      clearErrors('buyerGender');
    }

    // Validation pour 'coBuyerGender' seulement si 'cobuyer' est 'yes'
    if (cobuyer === 'yes' && !watch('coBuyerGender')) {
      setError('coBuyerGender', {
        type: 'manual',
        message: 'Ce champ est requis',
      });
    } else {
      clearErrors('coBuyerGender');
    }

    /*
    // Validation pour 'buyerFilesUrls'
    if (buyerFilesUrls.length < 1) {
      setError('buyerFilesUrls', {
        type: 'manual',
        message: 'Au moins 1 fichier est requis pour le nouveau titulaire.',
      });
    } else {
      clearErrors('buyerFilesUrls');
    }

    // Validation pour 'buyerResidencyFilesUrls'
    if (buyerClientType !== 'pro' && buyerResidencyFilesUrls.length < 1) {
      setError('buyerResidencyFilesUrls', {
        type: 'manual',
        message: 'Au moins 1 fichier de justificatif de domicile est requis.',
      });
    } else {
      clearErrors('buyerResidencyFilesUrls');
    }
    console.log('cobuyer', cobuyer);
    // Validation pour 'coBuyerFilesUrls' seulement si 'cobuyer' est 'yes'
    if (cobuyer === 'yes' && coBuyerFilesUrls.length < 1) {
      setError('coBuyerFilesUrls', {
        type: 'manual',
        message: 'Au moins 1 fichier est requis pour le co-titulaire.',
      });
    } else {
      clearErrors('coBuyerFilesUrls');
    }
    */

    // Déclenche la revalidation du formulaire pour mettre à jour l'état du bouton de soumission
    trigger();
  }, [
    sellerFilesUrls,
    buyerFilesUrls,
    buyerResidencyFilesUrls,
    coBuyerFilesUrls,
    cobuyer,
    setError,
    clearErrors,
    trigger,
  ]);

  useEffect(() => {
    if (coBuyerClientType === 'person') {
      formMethods.resetField('coBuyerCompanyName');
      formMethods.resetField('coBuyerSiret');
    } else if (coBuyerClientType === 'pro') {
      formMethods.resetField('coBuyerGender');
    }
  }, [coBuyerClientType]);

  // Afficher ou masquer les champs du co-titulaire basé sur le choix de l'utilisateur
  useEffect(() => {
    setValue('cobuyer', cgData && cgData.CoBuyerType ? 'yes' : 'no');
  }, [cgData, setValue]);

  useEffect(() => {
    console.log('cgData au loading', cgData);
    if (cgData) {
      reset({
        regPlate: cgData.RegPlate ?? '',
        vin: cgData.VIN ?? '',
        numFormule: cgData.NumFormule ?? '',
        purchaseDateTime: formatUTCDateTime(cgData.PurchaseDateTime) ?? '',
        buyerClientType: cgData.BuyerType ?? 'person',
        buyerFirstName: cgData.BuyerFirstName ?? '',
        buyerLastName: cgData.BuyerLastName ?? '',
        buyerUsualName: cgData.BuyerUsualName ?? '',
        buyerGender: cgData.BuyerGender ?? '',
        buyerDateOfBirth: cgData.BuyerDateOfBirth ? cgData.BuyerDateOfBirth.substring(0, 10) : '',
        buyerPlaceOfBirth: cgData.BuyerPlaceOfBirth ?? '',
        buyerCountryOfBirth: cgData.BuyerCountryOfBirth ?? '',
        buyerDepartmentOfBirth: cgData.BuyerDepartmentOfBirth ?? '',
        buyerDocumentType: cgData.BuyerDocumentType ?? '',
        buyerCompanyName: cgData.BuyerCompanyName ?? '',
        buyerSiret: cgData.BuyerSIRET ?? '',
        buyerAddressNumber: cgData.BuyerAddressNumber ?? '',
        buyerAddressExtension: cgData.BuyerAddressExtension ?? '',
        buyerAddressType: cgData.BuyerAddressType ?? '',
        buyerAddressName: cgData.BuyerAddressName ?? '',
        buyerAddressPlace: cgData.BuyerAddressPlace ?? '',
        buyerAddressStairs: cgData.BuyerAddressStairs ?? '',
        buyerAddressComp: cgData.BuyerAddressComplement ?? '',
        buyerAddressPostalBox: cgData.BuyerAddressPostalBox ?? '',
        buyerCity: cgData.BuyerCity ?? '',
        buyerPostalCode: cgData.BuyerPostalCode ?? '',
        buyerCountry: cgData.BuyerCountry ?? '',
        buyerPhone: cgData.BuyerPhone ?? '',
        buyerResidenceType: cgData.BuyerResidenceType ?? 'owner',
        sellerClientType: cgData.SellerType ?? 'person',
        sellerFirstName: cgData.SellerFirstName ?? '',
        sellerLastName: cgData.SellerLastName ?? '',
        sellerUsualName: cgData.SellerUsualName ?? '',
        sellerGender: cgData.SellerGender ?? 'mr',
        sellerSiret: cgData.SellerSIRET ?? '',
        sellerCompanyName: cgData.SellerCompanyName ?? '',
        cobuyer: cgData.CoBuyerType ? 'yes' : 'no',
        coBuyerClientType: cgData.CoBuyerType ?? 'person',
        coBuyerGender: cgData.CoBuyerGender ?? 'mr',
        coBuyerFirstName: cgData.CoBuyerFirstName ?? '',
        coBuyerLastName: cgData.CoBuyerLastName ?? '',
        coBuyerUsualName: cgData.CoBuyerUsualName ?? '',
        coBuyerCompanyName: cgData.CoBuyerCompanyName ?? '',
        coBuyerSiret: cgData.CoBuyerSIRET ?? '',
        sellerFilesUrls: cgData.SellerFiles ? cgData.SellerFiles.split(',') : [],
        buyerFilesUrls: cgData.BuyerFiles ? cgData.BuyerFiles.split(',') : [],
        buyerResidencyFilesUrls: cgData.BuyerResidencyFiles ? cgData.BuyerResidencyFiles.split(',') : [],
        coBuyerFilesUrls: cgData.CoBuyerFiles ? cgData.CoBuyerFiles.split(',') : [],
      });
    }
  }, [cgData, reset]);

  useEffect(() => {
    if (!cgData) {
      console.log('cgData is null');
      setValue('buyerDateOfBirth', ''); // Force à être vide après le rendu
      setValue('purchaseDateTime', ''); // Force à être vide après le rendu
    }
  }, []);

  const handlePostalCodeChange = async (event: any, value: string | any[]) => {
    if (value && value.length === 5) {
      try {
        const response = await makeAuthenticatedApiCall('get', `/api/geo/pc/?searchParam=${value}`);
        if (response.data && response.data.length > 0) {
          const filteredCities = response.data.map((item: { name: any; postcode: any }) => ({
            label: item.name,
            value: item.postcode,
          }));
          setCityOptions(filteredCities);
        } else {
          setCityOptions([]);
        }
      } catch (error) {
        console.error('Error fetching city data:', error);
        setCityOptions([]);
      }
    } else {
      setCityOptions([]);
    }
  };

  const handleBirthCityChange = async (event: any, value: string) => {
    if (value && value.length > 3) {
      try {
        const response = await makeAuthenticatedApiCall(
          'get',
          `/api/geo/cities/?searchParam=${value}&searchType=municipality`,
        );
        if (response.data && response.data.length > 0) {
          const birthDepartmentOptions = response.data.map((item: { name: any; postcode: any }) => ({
            label: item.name,
            value: item.postcode,
          }));
          setBirthDepartmentOptions(birthDepartmentOptions);
        } else {
          setBirthDepartmentOptions([]);
        }
      } catch (error) {
        console.error('Error fetching city data:', error);
        setBirthDepartmentOptions([]);
      }
    } else {
      setBirthDepartmentOptions([]);
    }
  };

  const handleFinalConfirm = async () => {
    setIsLoading(true);
    const formData = formMethods.getValues(); // Récupérer les données du formulaire actuel
    console.log('formData', formData);

    setIsConfirmModalOpen(false); // Fermer la modale de confirmation
    try {
      await handleSave(formData);
    } catch (error: any) {
      console.error(error);
      openSnackbar(
        'Il y a eu une erreur au moment de la validation. Veuillez re-controler les dates et re-soumettre',
        'error',
      );
    }
    setIsDialogOpen(false);
  };

  const handleSave = async (formData: any /* FormDataPerson | FormDataPro */) => {
    let payload, agencyInfo;

    try {
      payload = {
        ...formData,
        sellerFilesUrls: JSON.stringify(sellerFilesUrls),
        buyerFilesUrls: JSON.stringify(buyerFilesUrls),
        buyerResidencyFilesUrls: JSON.stringify(buyerResidencyFilesUrls),
        coBuyerFilesUrls: JSON.stringify(coBuyerFilesUrls),
        agencyID: currentUser?.data?.organization?.id ?? currentUser?.data?.data?.idPipedrive,
        personID: currentUser?.data?.id,
        agencyEmail: currentUser?.data?.primary_email,
        agencyName: currentUser?.data?.organization?.name,
        agencyAddress: currentUser?.data?.organization?.address,
      };
      console.log('submissionData', payload);

      agencyInfo = {
        agencyID: currentUser?.data?.organization?.id ?? currentUser?.data?.data?.idPipedrive,
        personID: currentUser?.data?.id,
        agencyEmail: currentUser?.data?.primary_email,
        agencyName: currentUser?.data?.organization?.name,
        agencyAddress: currentUser?.data?.organization?.address,
      };
      console.log('agencyInfo', agencyInfo);

      // Update the agency data in state
      setAgencyData(agencyInfo);
    } catch (error: any) {
      console.error(error);
      openSnackbar("Une erreur est survenue lors de la récupération des informations de l'utilisateur", 'error');
    }
    const departmentValue = buyerPostalCode.substring(0, 2); // Extrait les deux premiers chiffres du code postal

    if (!payload.purchaseDateTime || (payload.buyerClientType === 'person' && !payload.buyerDateOfBirth)) {
      openSnackbar(
        'Il arrive que les dates ne soient pas correctements prises en comptes avec Safari. Veuillez re-controler et re-soumettre',
        'error',
      );
      setIsLoading(false);
      throw new Error(
        'Il arrive que les dates ne soient pas correctements prises en comptes avec Safari. Veuillez re-controler et re-soumettre',
      );
    }

    try {
      const responseSave = await makeAuthenticatedApiCall('post', '/api/cg', payload);
      openSnackbar('Les modifications ont bien été prises en compte', 'success');
      const responseECG = await makeAuthenticatedApiCall('post', '/api/misiv/ecg', {
        regPlate: regPlateValue,
        department: departmentValue,
      });

      setTaxData(responseECG.data);
      setRegistrationId(responseSave.data.registrationId);
      setModalOpen(true);
    } catch (error: any) {
      console.error(error);
      // Vérifie si l'erreur provient d'une réponse HTTP, et utilisez le message d'erreur du serveur
      const errorMessage =
        error.response && error.response.data && error.response.data.details
          ? `ECG Error: ${error.response.data.details}`
          : "Une erreur inattendue s'est produite. Veuillez vérifier à nouveau les champs, puis réessayer.";
      openSnackbar(errorMessage, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  // Fonction pour formater la plaque d'immatriculation avec des tirets
  const formatRegPlate = (value: string) => {
    // Retire tous les caractères non-alphanumériques et les tirets existants
    const alphanumericValue = value.replace(/[^A-Z0-9]/gi, '');
    // Ajoute un tiret après le 2ème caractère et après le 5ème caractère si le format correspond
    if (alphanumericValue.length > 2 && alphanumericValue.length <= 5) {
      return `${alphanumericValue.slice(0, 2)}-${alphanumericValue.slice(2)}`;
    } else if (alphanumericValue.length > 5) {
      return `${alphanumericValue.slice(0, 2)}-${alphanumericValue.slice(2, 5)}-${alphanumericValue.slice(5)}`;
    }

    return alphanumericValue.substring(0, 9);
  };

  console.log('errors', errors);

  const renderErrorMessage = (error: any) => {
    if (error?.type === 'pattern') {
      return (
        <span>
          Le format de la plaque n'est pas valide. S'il s'agit d'une ancienne plaque, veuillez nous envoyer le dossier à{' '}
          <a href="mailto:skipcarcg@gmail.com" target="_blank" rel="noopener noreferrer">
            skipcarcg@gmail.com
          </a>
          .
        </span>
      );
    }
    return error?.message;
  };

  return registrationIdGetParameter && isLoadingCGData ? (
    <CircularProgress sx={{ marginRight: 2 }} size={24} />
  ) : (
    <>
      <Container maxWidth={false}>
        <Box
          sx={{
            margin: { xs: '2em 1em', md: '2em 3em' },
            backgroundColor: '#f5f5f5',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            display: registrationIdGetParameter ? 'none' : 'block',
          }}
        >
          <Typography variant="body1" align="center" gutterBottom>
            Comment faire votre demande
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                secondary={
                  <span>
                    <strong>ÉTAPE 1 :</strong> Utilisez notre calculateur en ligne pour estimer le prix de votre
                    certificat d’immatriculation en remplissant simplement les champs requis.
                  </span>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary={
                  <span>
                    <strong>ÉTAPE 2 :</strong> Après validation, consultez votre facture et procédez au paiement par
                    virement pour confirmer la transaction (si vous souhaitez passer par facturation quinzaine ou
                    mensuel, veuillez nous contacter via{' '}
                    <a href="mailto:skipcarcg@gmail.com" target="_blank">
                      skipcarcg@gmail.com
                    </a>
                    ).
                  </span>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary={
                  <span>
                    <strong>ÉTAPE 3 :</strong> Une fois le paiement effectué, notre équipe enclenche le processus pour
                    traiter votre demande dans les meilleurs délais.
                  </span>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary={
                  <span>
                    <strong>ÉTAPE 4 :</strong> Obtenez immédiatement votre Accusé d’Enregistrement de Changement de
                    Titulaire ou votre Certificat Provisoire d’Immatriculation pour que votre client puisse circuler
                    pendant 1 mois. Recevez ensuite votre carte grise définitive par courrier sécurisé de l’Imprimerie
                    Nationale.
                  </span>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                secondary={
                  <span>
                    <strong>ÉTAPE 5 :</strong> Suivez facilement toutes vos demandes à travers notre interface et
                    retrouvez vos récépissés et factures dans votre historique.
                  </span>
                }
              />
            </ListItem>
          </List>
          <AdvantagesModal />
        </Box>
        <Typography variant="h1" align="center" sx={{ margin: { xs: '0.5em auto 1em', md: '1em auto' } }}>
          Demande de carte grise
        </Typography>
        <Paper sx={{ marginTop: 1, marginBottom: { xs: 4, md: 0 } }} elevation={0}>
          <Box sx={{ p: { xs: 0, md: 4 } }}>
            <FormProvider {...formMethods}>
              <form>
                <Grid container>
                  <FormLabel component="legend">Véhicule</FormLabel>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="purchaseDateTime"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            disabled={isReadOnly}
                            autoFocus
                            margin="dense"
                            className="inputElement"
                            id="purchaseDateTime"
                            label="Date et heure de la vente"
                            type="datetime-local"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="regPlate"
                        control={control}
                        rules={validationRules.regPlate}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            disabled={isReadOnly}
                            className="inputElement"
                            {...field}
                            inputProps={{ maxLength: 9 }} // Limite à 9 caractères
                            value={regPlateValue}
                            helperText={renderErrorMessage(errors.regPlate)}
                            onChange={(e) => {
                              // Met à jour la valeur du champ avec la valeur formatée et limitée
                              const upperCaseValue = e.target.value.toUpperCase();
                              field.onChange(formatRegPlate(upperCaseValue));
                            }}
                            error={!!errors?.regPlate?.message}
                            margin="dense"
                            label="Immatriculation"
                            type="text"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="vin"
                        control={control}
                        rules={validationRules.vin}
                        defaultValue={''}
                        render={({ field }) => (
                          <TextField
                            disabled={isReadOnly}
                            {...field}
                            error={!!errors.vin}
                            margin="dense"
                            className="inputElement"
                            id="vin"
                            label="Numéro VIN"
                            type="text"
                            fullWidth
                            inputProps={{ maxLength: 17 }}
                            onChange={(e) => field.onChange(removeSpaces(e.target.value))} // Enlève les espaces
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="numFormule"
                        control={control}
                        rules={validationRules.numFormule}
                        defaultValue={''}
                        render={({ field }) => (
                          <TextField
                            disabled={isReadOnly}
                            {...field}
                            error={!!errors.numFormule}
                            margin="dense"
                            className="inputElement"
                            id="numFormule"
                            label="Numéro de Formule"
                            type="text"
                            fullWidth
                            inputProps={{ maxLength: 11 }}
                            onChange={(e) => field.onChange(removeSpaces(e.target.value))} // Enlève les espaces
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography variant="body1" align="center">
                        Les fichiers peuvent être envoyés dans un seul fichier ou en plusieurs fichiers différents :
                      </Typography>
                      <Typography variant="body2" align="center">
                        Carte grise, Cerfa de déclaration de cession (Cerfa 15776*01), Cerfa de demande
                        d’immatriculation d’un véhicule (Cerfa 13750*05), Mandat à un professionnel (Cerfa 13757*03),
                        Attestation d’assurance, Contrôle technique.
                      </Typography>
                      {/* https://www.service-public.fr/simulateur/calcul/15776 */}
                      {/* https://www.service-public.fr/particuliers/vosdroits/R13567 */}

                      {cgData ? (
                        cgData.sellerFilesUrls && <FilesOpener FilesUrls={cgData.sellerFilesUrls} />
                      ) : (
                        <FirebaseUploadComponent
                          onUploadComplete={(url) => sellerFilesUploadComplete(url)}
                          onDeleteComplete={(url) => sellerFilesDeleteComplete(url)}
                          uploadedUrls={sellerFilesUrls}
                        />
                      )}
                      {errors.sellerFilesUrls && typeof errors.sellerFilesUrls.message === 'string' && (
                        <Typography color="error" align="center">
                          {errors.sellerFilesUrls.message}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Divider
                    variant="fullWidth"
                    component="hr"
                    style={{
                      marginLeft: 24,
                      backgroundColor: '#fdd15f',
                      height: '2px',
                      width: 'calc(100% - 24px)',
                      border: 0,
                      marginTop: 30,
                      marginBottom: 30,
                    }}
                  />

                  <FormLabel component="legend">Ancien titulaire</FormLabel>
                  <Grid container alignItems="center" item xs={12} md={12}>
                    <Grid item>
                      <Typography variant="body2" component="div" sx={{ marginRight: 2 }}>
                        L'ancien propriétaire est un :
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Controller
                        name="sellerClientType"
                        control={control}
                        defaultValue="person"
                        render={({ field }) => (
                          <RadioGroup {...field} row>
                            <FormControlLabel
                              className="inputElement"
                              value="person"
                              disabled={isReadOnly}
                              control={<Radio />}
                              label="Particulier"
                            />
                            <FormControlLabel
                              disabled={isReadOnly}
                              className="inputElement"
                              value="pro"
                              control={<Radio />}
                              label="Pro"
                            />
                          </RadioGroup>
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    {sellerClientType === 'person' && (
                      <>
                        <Grid item xs={12} md={3} alignItems="center" display={'flex'}>
                          <Controller
                            name="sellerGender"
                            control={control}
                            defaultValue="mr"
                            rules={sellerClientType === 'person' ? validationRules.sellerGender : {}}
                            render={({ field }) => (
                              <RadioGroup {...field} row>
                                <FormControlLabel
                                  value="mr"
                                  control={<Radio />}
                                  label="Monsieur"
                                  className="inputElement"
                                  disabled={isReadOnly}
                                />
                                <FormControlLabel
                                  disabled={isReadOnly}
                                  value="mme"
                                  control={<Radio />}
                                  label="Madame"
                                  className="inputElement"
                                />
                              </RadioGroup>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Controller
                            name="sellerFirstName"
                            control={control}
                            rules={sellerClientType === 'person' ? validationRules.sellerFirstName : {}}
                            defaultValue={''}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                disabled={isReadOnly}
                                error={!!errors.sellerFirstName}
                                className="inputElement"
                                margin="dense"
                                id="sellerFirstName"
                                label="Prénom"
                                type="text"
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Controller
                            name="sellerLastName"
                            control={control}
                            rules={sellerClientType === 'person' ? validationRules.sellerLastName : {}}
                            defaultValue={''}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                disabled={isReadOnly}
                                id="sellerLastName"
                                error={!!errors.sellerLastName}
                                className="inputElement"
                                margin="dense"
                                label="Nom"
                                type="text"
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Controller
                            name="sellerUsualName"
                            rules={{}}
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                id="sellerUsualName"
                                error={!!errors.sellerUsualName}
                                disabled={isReadOnly}
                                className="inputElement"
                                margin="dense"
                                label="Nom d'usage"
                                type="text"
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                      </>
                    )}
                    {sellerClientType === 'pro' && (
                      <>
                        <Grid item xs={12} md={4}>
                          <Controller
                            name="sellerSiret"
                            control={control}
                            rules={
                              sellerClientType === 'pro'
                                ? {
                                    required: 'Ce champ est requis',
                                    minLength: {
                                      value: 14,
                                      message: 'Le SIRET doit contenir exactement 14 caractères',
                                    },
                                    maxLength: {
                                      value: 14,
                                      message: 'Le SIRET doit contenir exactement 14 caractères',
                                    },
                                  }
                                : {
                                    minLength: {
                                      value: 14,
                                      message: 'Le SIRET doit contenir exactement 14 caractères',
                                    },
                                    maxLength: {
                                      value: 14,
                                      message: 'Le SIRET doit contenir exactement 14 caractères',
                                    },
                                  }
                            }
                            defaultValue={''}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                disabled={isReadOnly}
                                className="inputElement"
                                error={!!errors.sellerSiret}
                                margin="dense"
                                id="sellerSiret"
                                label="SIRET"
                                type="text"
                                fullWidth
                                inputProps={{ maxLength: 14 }} // Limite le nombre de caractères que l'utilisateur peut taper
                                onChange={(e) => field.onChange(removeSpaces(e.target.value))} // Enlève les espaces
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Controller
                            name="sellerCompanyName"
                            control={control}
                            rules={sellerClientType === 'pro' ? validationRules.sellerCompanyName : {}}
                            defaultValue={''}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                disabled={isReadOnly}
                                margin="dense"
                                error={!!errors.sellerCompanyName}
                                id="sellerCompanyName"
                                className="inputElement"
                                label="Raison sociale"
                                type="text"
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Divider
                    variant="fullWidth"
                    component="hr"
                    style={{
                      marginLeft: 24,
                      backgroundColor: '#fdd15f',
                      height: '2px',
                      width: 'calc(100% - 24px)',
                      border: 0,
                      marginTop: 30,
                      marginBottom: 30,
                    }}
                  />
                  <FormLabel component="legend">Nouveau titulaire</FormLabel>
                  <Grid container alignItems="center" item xs={12} md={12}>
                    <Grid item>
                      <Typography variant="body2" component="div" sx={{ marginRight: 2 }}>
                        Le nouveau propriétaire est un :
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Controller
                        name="buyerClientType"
                        control={control}
                        defaultValue="person"
                        render={({ field }) => (
                          <RadioGroup {...field} row>
                            <FormControlLabel
                              disabled={isReadOnly}
                              className="inputElement"
                              value="person"
                              control={<Radio />}
                              label="Particulier"
                            />
                            <FormControlLabel
                              disabled={isReadOnly}
                              className="inputElement"
                              value="pro"
                              control={<Radio />}
                              label="Pro"
                            />
                          </RadioGroup>
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Grid container spacing={2}>
                        {buyerClientType === 'person' && (
                          <>
                            <Grid item xs={12} md={3} alignItems="center" display={'flex'}>
                              <Controller
                                name="buyerGender"
                                control={control}
                                rules={buyerClientType === 'person' ? validationRules.buyerGender : {}}
                                defaultValue="mr"
                                render={({ field }) => (
                                  <RadioGroup {...field} row>
                                    <FormControlLabel
                                      value="mr"
                                      control={<Radio />}
                                      label="Monsieur"
                                      className="inputElement"
                                      disabled={isReadOnly}
                                    />
                                    <FormControlLabel
                                      value="mme"
                                      control={<Radio />}
                                      label="Madame"
                                      className="inputElement"
                                      disabled={isReadOnly}
                                    />
                                  </RadioGroup>
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Controller
                                name="buyerFirstName"
                                control={control}
                                rules={buyerClientType === 'person' ? validationRules.buyerFirstName : {}}
                                defaultValue={''}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    error={!!errors.buyerFirstName}
                                    disabled={isReadOnly}
                                    className="inputElement"
                                    margin="dense"
                                    id="buyerFirstName"
                                    label="Prénom"
                                    type="text"
                                    fullWidth
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Controller
                                name="buyerLastName"
                                control={control}
                                rules={buyerClientType === 'person' ? validationRules.buyerLastName : {}}
                                defaultValue={''}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    id="buyerLastName"
                                    error={!!errors.buyerLastName}
                                    disabled={isReadOnly}
                                    className="inputElement"
                                    margin="dense"
                                    label="Nom"
                                    type="text"
                                    fullWidth
                                  />
                                )}
                              />
                            </Grid>

                            <Grid item xs={12} md={3}>
                              <Controller
                                name="buyerUsualName"
                                control={control}
                                defaultValue={''}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    id="buyerUsualName"
                                    error={!!errors.buyerUsualName}
                                    disabled={isReadOnly}
                                    className="inputElement"
                                    margin="dense"
                                    label="Nom d'usage"
                                    type="text"
                                    fullWidth
                                  />
                                )}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    {buyerClientType === 'pro' ? (
                      <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <Controller
                              name="buyerSiret"
                              control={control}
                              rules={
                                buyerClientType === 'pro'
                                  ? {
                                      required: 'Ce champ est requis',
                                      minLength: {
                                        value: 14,
                                        message: 'Le SIRET doit contenir exactement 14 caractères',
                                      },
                                      maxLength: {
                                        value: 14,
                                        message: 'Le SIRET doit contenir exactement 14 caractères',
                                      },
                                    }
                                  : {
                                      minLength: {
                                        value: 14,
                                        message: 'Le SIRET doit contenir exactement 14 caractères',
                                      },
                                      maxLength: {
                                        value: 14,
                                        message: 'Le SIRET doit contenir exactement 14 caractères',
                                      },
                                    }
                              }
                              defaultValue={''}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  className="inputElement"
                                  error={!!errors.buyerSiret}
                                  disabled={isReadOnly}
                                  margin="dense"
                                  id="buyerSiret"
                                  label="SIRET"
                                  type="text"
                                  fullWidth
                                  inputProps={{ maxLength: 14 }} // Limite le nombre de caractères que l'utilisateur peut taper
                                  onChange={(e) => field.onChange(removeSpaces(e.target.value))} // Enlève les espaces
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Controller
                              name="buyerCompanyName"
                              control={control}
                              rules={buyerClientType === 'pro' ? { required: 'Ce champ est requis' } : {}}
                              defaultValue={''}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  margin="dense"
                                  error={!!errors.buyerCompanyName}
                                  disabled={isReadOnly}
                                  id="buyerCompanyName"
                                  className="inputElement"
                                  label="Raison sociale"
                                  type="text"
                                  fullWidth
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item xs={12} md={6}></Grid>
                    )}
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item xs={12} md={2}>
                      <Controller
                        name="buyerAddressNumber"
                        control={control}
                        rules={validationRules.addressNumber}
                        defaultValue={''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            disabled={isReadOnly}
                            error={!!errors.buyerAddressNumber}
                            className="inputElement"
                            margin="dense"
                            id="buyerAddressNumber"
                            label="Numéro d'adresse"
                            type="number"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Controller
                        name="buyerAddressExtension"
                        control={control}
                        defaultValue={''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            className="inputElement"
                            margin="dense"
                            disabled={isReadOnly}
                            id="buyerAddressExtension"
                            label="Extension d'adresse"
                            type="text"
                            fullWidth
                            inputProps={{ maxLength: 7 }}
                            placeholder="Ex: BIS"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Controller
                        name="buyerAddressType"
                        control={control}
                        defaultValue={''}
                        rules={{ required: 'Ce champ est requis' }}
                        render={({ field, fieldState: { error } }) => (
                          <FormControl fullWidth margin="dense" error={!!error}>
                            <InputLabel
                              margin="dense"
                              disabled={true}
                              className="inputElement"
                              id={`${field.name}-label`}
                            >
                              Type d'adresse
                            </InputLabel>
                            <Select
                              disabled={isReadOnly}
                              sx={{ minHeight: 'inherit', height: '56px' }}
                              className="inputElement"
                              {...field}
                              labelId={`${field.name}-label`}
                              id={field.name}
                              label="Type d'adresse"
                              value={field.value ? field.value.toLowerCase() : ''}
                            >
                              <MenuItem value="rue">Rue</MenuItem>
                              <MenuItem value="all">Allée</MenuItem>
                              <MenuItem value="avn">Avenue</MenuItem>
                              <MenuItem value="blvd">Boulevard</MenuItem>
                              <MenuItem value="chau">Chaussée</MenuItem>
                              <MenuItem value="chm">Chemin</MenuItem>
                              <MenuItem value="cour">Cour</MenuItem>
                              <MenuItem value="fbg">Faubourg</MenuItem>
                              <MenuItem value="fort">Fort</MenuItem>
                              <MenuItem value="imp">Impasse</MenuItem>
                              <MenuItem value="ldit">Lieu-Dit</MenuItem>
                              <MenuItem value="parc">Parc</MenuItem>
                              <MenuItem value="pass">Passage</MenuItem>
                              <MenuItem value="plc">Place</MenuItem>
                              <MenuItem value="quai">Quai</MenuItem>
                              <MenuItem value="rte">Route</MenuItem>
                              <MenuItem value="sent">Sentier</MenuItem>
                              <MenuItem value="squa">Square</MenuItem>
                              <MenuItem value="sans">Inconnu</MenuItem>
                            </Select>
                            {error && <FormHelperText>{error.message}</FormHelperText>}
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="buyerAddressName"
                        control={control}
                        rules={validationRules.addressName}
                        defaultValue={cgData?.buyerAddressName ?? ''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            disabled={isReadOnly}
                            className="inputElement"
                            error={!!errors.buyerAddressName}
                            margin="dense"
                            id="buyerAddressName"
                            label="Nom de la voie (Rue, Allée, ou Avenue, ne doivent pas apparaitre ici)"
                            type="text"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        name="buyerAddressPlace"
                        control={control}
                        rules={{}}
                        defaultValue={cgData?.buyerAddressPlace ?? ''}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            disabled={isReadOnly}
                            className="inputElement"
                            error={!!error}
                            helperText={error ? error.message : ''}
                            margin="dense"
                            id="buyerAddressPlace"
                            label="Lieu-dit"
                            type="text"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        name="buyerAddressStairs"
                        control={control}
                        rules={{}}
                        defaultValue={cgData?.buyerAddressStairs ?? ''}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            disabled={isReadOnly}
                            className="inputElement"
                            error={!!error}
                            helperText={error ? error.message : ''}
                            margin="dense"
                            id="buyerAddressStairs"
                            label="Étage/Escalier/Appartement"
                            type="text"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        name="buyerAddressComp"
                        control={control}
                        rules={{}}
                        defaultValue={cgData?.buyerAddressComp ?? ''}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            disabled={isReadOnly}
                            className="inputElement"
                            error={!!error}
                            helperText={error ? error.message : ''}
                            margin="dense"
                            id="buyerAddressComp"
                            label="Complément d'adresse"
                            type="text"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        name="buyerAddressPostalBox"
                        control={control}
                        rules={{}}
                        defaultValue={cgData?.buyerAddressPostalBox ?? ''}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            disabled={isReadOnly}
                            className="inputElement"
                            error={!!error}
                            helperText={error ? error.message : ''}
                            margin="dense"
                            id="buyerAddressPostalBox"
                            label="Boîte postale"
                            type="text"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      {registrationIdGetParameter && isLoadingCGData ? (
                        <CircularProgress sx={{ marginRight: 2 }} size={24} />
                      ) : registrationIdGetParameter ? (
                        <TextField
                          value={buyerPostalCode}
                          disabled={true}
                          className="inputElement"
                          error={!!errors.buyerPostalCode}
                          margin="dense"
                          id="buyerPostalCode"
                          label="Code postal"
                          InputLabelProps={{ shrink: true }}
                          type="text"
                          fullWidth
                        />
                      ) : (
                        <Controller
                          name="buyerPostalCode"
                          control={control}
                          rules={validationRules.postalCode}
                          defaultValue={cgData?.BuyerPostalCode ?? ''}
                          render={({ field }) => (
                            <Autocomplete
                              freeSolo
                              disablePortal
                              disabled={isReadOnly}
                              className="inputElement"
                              options={cityOptions}
                              getOptionLabel={(option: any) => `${option.label} (${option.value})`}
                              isOptionEqualToValue={(option, value) => option.value === value}
                              onInputChange={(event, value) => handlePostalCodeChange(event, value)}
                              onChange={(event, value) => {
                                const postalCode = value ? value.value : '';
                                const city = value ? value.label : '';
                                setSelectedPostalCode(postalCode);
                                setSelectedCity(city);
                                field.onChange(postalCode);
                                setValue('buyerCity', city);
                                setValue('buyerCountry', 'France'); // Met à jour automatiquement le champ "Pays"
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  sx={{
                                    minHeight: 'inherit',
                                    height: '56px',
                                    '& .MuiOutlinedInput-root': { height: '56px' },
                                  }}
                                  value={selectedPostalCode ?? cgData?.buyerPostalCode}
                                  onChange={(event) => {
                                    setSelectedPostalCode(event.target.value);
                                    field.onChange(event.target.value);
                                  }}
                                  error={!!errors.buyerPostalCode}
                                  margin="dense"
                                  id="buyerPostalCode"
                                  label="Code postal"
                                  type="text"
                                  fullWidth
                                />
                              )}
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <Controller
                        name="buyerCity"
                        control={control}
                        rules={validationRules.city}
                        defaultValue={(cgData && cgData?.buyerCity) ?? ''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            onChange={(event) => {
                              setSelectedCity(event.target.value);
                              field.onChange(event.target.value);
                            }}
                            disabled={isReadOnly}
                            error={!!errors.buyerCity}
                            className="inputElement"
                            margin="dense"
                            id="buyerCity"
                            label="Ville"
                            type="text"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        name="buyerCountry"
                        control={control}
                        rules={validationRules.country}
                        defaultValue={(cgData && cgData?.buyerCountry) ?? 'France'}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            disabled={isReadOnly}
                            className="inputElement"
                            error={!!errors.buyerCountry}
                            margin="dense"
                            id="buyerCountry"
                            label="Pays"
                            type="text"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        name="buyerPhone"
                        control={control}
                        rules={{ required: 'Ce champ est requis' }}
                        defaultValue={cgData?.buyerPhone ?? ''}
                        render={({ field }) => (
                          <PhoneInput
                            {...field}
                            country={'fr'}
                            disabled={isReadOnly}
                            inputStyle={{
                              fontFamily: 'Urbanist',
                              fontWeight: 500,
                              color: 'black',
                              width: '100%',
                              marginTop: '8px',
                              height: '56px',
                            }}
                            specialLabel="Numéro de téléphone portable"
                            onChange={(phone) => field.onChange(phone)}
                          />
                        )}
                      />
                      {errors.buyerPhone && (
                        <Typography color="error" variant="body2">
                          {errors.buyerPhone.message}
                        </Typography>
                      )}
                    </Grid>
                    {buyerClientType === 'person' && (
                      <>
                        <Grid container alignItems="center" item xs={12} md={12}>
                          <Grid item xs={6} md={3}>
                            <Typography variant="body2" component="div" sx={{ marginRight: 2 }}>
                              Type d'hébergement :
                            </Typography>
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <Controller
                              name="buyerResidenceType"
                              control={control}
                              defaultValue="owner"
                              render={({ field }) => (
                                <RadioGroup {...field} row>
                                  <FormControlLabel
                                    className="inputElement"
                                    value="owner"
                                    disabled={isReadOnly}
                                    control={<Radio />}
                                    label="Titulaire"
                                  />
                                  <FormControlLabel
                                    className="inputElement"
                                    value="guest"
                                    disabled={isReadOnly}
                                    control={<Radio />}
                                    label="Hébergé"
                                  />
                                </RadioGroup>
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Typography variant="body2" component="div" textAlign={'center'}>
                              {buyerResidenceType === 'owner'
                                ? 'Justificatif de domicile'
                                : "Justificatif de domicile de l’hébergeur, Pièce d'identité de l'hébergeur, Attestation sur l'honneur et Justificatif de domicile"}
                            </Typography>
                            {cgData ? (
                              cgData.buyerResidencyFilesUrls && (
                                <FilesOpener FilesUrls={cgData.buyerResidencyFilesUrls} />
                              )
                            ) : (
                              <FirebaseUploadComponent
                                onUploadComplete={(url) => buyerResidencyFilesUploadComplete(url)}
                                onDeleteComplete={(url) => buyerResidencyFilesDeleteComplete(url)}
                                uploadedUrls={buyerResidencyFilesUrls}
                              />
                            )}
                            {errors.buyerResidencyFilesUrls &&
                              typeof errors.buyerResidencyFilesUrls.message === 'string' && (
                                <Typography color="error" align="center">
                                  {errors.buyerResidencyFilesUrls.message}
                                </Typography>
                              )}
                          </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                          <Controller
                            name="buyerDateOfBirth"
                            rules={buyerClientType === 'person' ? { required: 'Ce champ est requis' } : {}}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <TextField
                                {...field}
                                disabled={isReadOnly}
                                id="buyerDateOfBirth"
                                error={!!errors.buyerDateOfBirth}
                                sx={{ marginTop: '8px' }}
                                className="inputElement"
                                label="Date de naissance"
                                type="date"
                                fullWidth
                                margin="dense"
                                InputLabelProps={{ shrink: true }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          {registrationIdGetParameter ? (
                            <TextField
                              sx={{
                                minHeight: 'inherit',
                                height: '56px',
                                '& .MuiOutlinedInput-root': { height: '56px' },
                              }}
                              className="inputElement"
                              value={cgData.BuyerPlaceOfBirth}
                              disabled={isReadOnly}
                              error={!!errors.buyerPlaceOfBirth}
                              margin="dense"
                              label="Lieu de naissance"
                              fullWidth
                            />
                          ) : (
                            <Controller
                              name="buyerPlaceOfBirth"
                              control={control}
                              rules={buyerClientType === 'person' ? { required: 'Ce champ est requis' } : {}}
                              render={({ field }) => (
                                <Autocomplete
                                  disablePortal
                                  freeSolo
                                  disabled={isReadOnly}
                                  className="inputElement"
                                  id="buyerPlaceOfBirth"
                                  options={birthDepartmentOptions}
                                  getOptionLabel={(option: any) => `${option.label} (${option.value})`}
                                  isOptionEqualToValue={(option, value) => option.label === value.label}
                                  onInputChange={handleBirthCityChange}
                                  onChange={(event, value) => {
                                    field.onChange(value ? value.label : '');
                                    setValue('buyerDepartmentOfBirth', value ? value.value.slice(0, 2) : '');
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      sx={{
                                        minHeight: 'inherit',
                                        height: '56px',
                                        '& .MuiOutlinedInput-root': { height: '56px' },
                                      }}
                                      {...params}
                                      {...field}
                                      value={(cgData && cgData?.buyerPlaceOfBirth) ?? ''}
                                      disabled={isReadOnly}
                                      error={!!errors.buyerPlaceOfBirth}
                                      margin="dense"
                                      label="Lieu de naissance"
                                      fullWidth
                                    />
                                  )}
                                />
                              )}
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Controller
                            name="buyerDepartmentOfBirth"
                            control={control}
                            rules={{
                              validate: {
                                required: (value) =>
                                  isFrance ? (value.trim() === '' ? 'Ce champ est requis pour la France' : true) : true,
                              },
                            }}
                            render={({ field, fieldState: { error } }) => (
                              <TextField
                                {...field}
                                label="Numéro de département de naissance"
                                variant="outlined"
                                margin="dense"
                                className="inputElement"
                                fullWidth
                                disabled={!isFrance || isReadOnly}
                                error={!!error}
                                helperText={error ? error.message : ''}
                                inputProps={{ maxLength: 3 }}
                                InputLabelProps={{
                                  style: {
                                    fontWeight: 'bold', // Mettre le label en gras
                                    fontSize: '1rem', // Réduire la taille de la police si nécessaire
                                  },
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Controller
                            name="buyerCountryOfBirth"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                disabled={isReadOnly}
                                className="inputElement"
                                label="Pays de naissance"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                error={!!errors.buyerCountryOfBirth}
                                helperText={errors.buyerCountryOfBirth ? errors.buyerCountryOfBirth.message : ''}
                              />
                            )}
                          />
                        </Grid>
                        {/*
                        <Grid item xs={12} md={3}>
                          <Controller
                            name="buyerDocumentId"
                            rules={buyerClientType === 'person' ? { required: 'Ce champ est requis' } : {}}
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                margin="dense"
                                error={!!errors.buyerDocumentId}
                                className="inputElement"
                                id="buyerDocumentId"
                                label="ID du document"
                                type="text"
                                fullWidth
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} md={3}>
                          <Controller
                            name="buyerDocumentType"
                            rules={buyerClientType === 'person' ? { required: 'Ce champ est requis' } : {}}
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                              <Select
                                {...field}
                                disabled={isReadOnly}
                                error={!!errors.buyerDocumentType}
                                fullWidth
                                margin="dense"
                                className="inputElement"
                                displayEmpty
                              >
                                <MenuItem value="" disabled>
                                  Type de document
                                </MenuItem>
                                <MenuItem value="id_card">Carte d'identité</MenuItem>
                                <MenuItem value="passport">Passeport</MenuItem>
                                <MenuItem value="driving_licence">Permis de conduire</MenuItem>
                                <MenuItem value="residence_permit">Titre de séjour</MenuItem>
                              </Select>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Controller
                            name="buyerDocumentIssueDate"
                            rules={buyerClientType === 'person' ? { required: 'Ce champ est requis' } : {}}
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                sx={{ marginTop: '0px' }}
                                margin="dense"
                                error={!!errors.buyerDocumentIssueDate}
                                className="inputElement"
                                id="buyerDocumentIssueDate"
                                label="Date de délivrance du document"
                                type="date"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                              />
                            )}
                          />
                        </Grid>
                        */}
                      </>
                    )}
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" component="div" textAlign={'center'}>
                        {buyerClientType === 'person'
                          ? "Pièce d'identité & Permis de conduire"
                          : "Permis de conduire, KBIS & Pièce d'identité du gérant"}
                      </Typography>
                      {cgData ? (
                        cgData.buyerFilesUrls && <FilesOpener FilesUrls={cgData.buyerFilesUrls} />
                      ) : (
                        <FirebaseUploadComponent
                          onUploadComplete={(url) => buyerFilesUploadComplete(url)}
                          onDeleteComplete={(url) => buyerFilesDeleteComplete(url)}
                          uploadedUrls={buyerFilesUrls}
                        />
                      )}

                      {errors.buyerFilesUrls && typeof errors.buyerFilesUrls.message === 'string' && (
                        <Typography color="error" align="center">
                          {errors.buyerFilesUrls.message}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  {cobuyer === 'yes' && (
                    <Divider
                      variant="fullWidth"
                      component="hr"
                      style={{
                        marginLeft: 24,
                        backgroundColor: '#fdd15f',
                        height: '2px',
                        width: 'calc(100% - 24px)',
                        border: 0,
                        marginTop: 15,
                      }}
                    />
                  )}
                  <Grid container sx={{ marginTop: 2 }} alignItems="center" item xs={12} md={12}>
                    <Grid item>
                      <Typography variant="body1" component="div" sx={{ marginRight: 2 }}>
                        Il y a un co-titulaire :
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Controller
                        name="cobuyer"
                        control={control}
                        defaultValue="no"
                        render={({ field }) => (
                          <RadioGroup {...field} row>
                            <FormControlLabel
                              disabled={isReadOnly}
                              className="inputElement"
                              value="no"
                              control={<Radio />}
                              label="Non"
                            />
                            <FormControlLabel
                              disabled={isReadOnly}
                              className="inputElement"
                              value="yes"
                              control={<Radio />}
                              label="Oui"
                            />
                          </RadioGroup>
                        )}
                      />
                    </Grid>
                  </Grid>
                  {cobuyer === 'yes' && (
                    <>
                      <Grid container sx={{ marginTop: 2 }} alignItems="center" item xs={12} md={12}>
                        <Grid item>
                          <Typography variant="h6" sx={{ marginRight: 2 }}>
                            Le co-titulaire est un :
                          </Typography>
                        </Grid>

                        <Grid item xs={6} sm={4}>
                          <Controller
                            name="coBuyerClientType"
                            control={control}
                            defaultValue="person"
                            render={({ field }) => (
                              <RadioGroup {...field} row>
                                <FormControlLabel
                                  disabled={isReadOnly}
                                  className="inputElement"
                                  value="person"
                                  control={<Radio />}
                                  label="Particulier"
                                />
                                <FormControlLabel
                                  className="inputElement"
                                  value="pro"
                                  control={<Radio />}
                                  label="Professionnel"
                                  disabled={isReadOnly}
                                />
                              </RadioGroup>
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} alignItems="center">
                        {coBuyerClientType === 'person' ? (
                          <>
                            <Grid item xs={6} md={3}>
                              <Controller
                                name="coBuyerGender"
                                control={control}
                                rules={coBuyerClientType === 'person' ? { required: 'Ce champ est requis' } : {}}
                                defaultValue="mr"
                                render={({ field }) => (
                                  <RadioGroup {...field} row>
                                    <FormControlLabel
                                      value="mr"
                                      control={<Radio />}
                                      label="Monsieur"
                                      disabled={isReadOnly}
                                      className="inputElement"
                                    />
                                    <FormControlLabel
                                      value="mme"
                                      control={<Radio />}
                                      label="Madame"
                                      disabled={isReadOnly}
                                      className="inputElement"
                                    />
                                  </RadioGroup>
                                )}
                              />
                            </Grid>
                            <Grid item xs={6} md={3}>
                              <Controller
                                name="coBuyerFirstName"
                                control={control}
                                rules={coBuyerClientType === 'person' ? validationRules.coBuyerFirstName : {}}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    label="Prénom"
                                    disabled={isReadOnly}
                                    fullWidth
                                    margin="dense"
                                    className="inputElement"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6} md={3}>
                              <Controller
                                name="coBuyerLastName"
                                control={control}
                                rules={coBuyerClientType === 'person' ? validationRules.coBuyerLastName : {}}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    disabled={isReadOnly}
                                    label="Nom"
                                    fullWidth
                                    margin="dense"
                                    className="inputElement"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Controller
                                name="coBuyerUsualName"
                                control={control}
                                rules={{}}
                                defaultValue={''}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    disabled={isReadOnly}
                                    id="coBuyerUsualName"
                                    error={!!errors.coBuyerUsualName}
                                    className="inputElement"
                                    margin="dense"
                                    label="Nom d'usage"
                                    type="text"
                                    fullWidth
                                  />
                                )}
                              />
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={6} md={4} key="coBuyerCompanyName">
                              <Controller
                                name="coBuyerCompanyName"
                                control={control}
                                rules={coBuyerClientType === 'pro' ? { required: 'Ce champ est requis' } : {}}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    disabled={isReadOnly}
                                    label="Raison sociale"
                                    fullWidth
                                    margin="dense"
                                    className="inputElement"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6} md={4} key="coBuyerSiret">
                              <Controller
                                name="coBuyerSiret"
                                control={control}
                                rules={
                                  coBuyerClientType === 'pro'
                                    ? {
                                        required: 'Ce champ est requis',
                                        minLength: {
                                          value: 14,
                                          message: 'Le SIRET doit contenir exactement 14 caractères',
                                        },
                                        maxLength: {
                                          value: 14,
                                          message: 'Le SIRET doit contenir exactement 14 caractères',
                                        },
                                      }
                                    : {
                                        minLength: {
                                          value: 14,
                                          message: 'Le SIRET doit contenir exactement 14 caractères',
                                        },
                                        maxLength: {
                                          value: 14,
                                          message: 'Le SIRET doit contenir exactement 14 caractères',
                                        },
                                      }
                                }
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    disabled={isReadOnly}
                                    label="SIRET"
                                    fullWidth
                                    margin="dense"
                                    className="inputElement"
                                    error={!!errors.coBuyerSiret}
                                    helperText={errors.coBuyerSiret ? (errors.coBuyerSiret.message as string) : ''}
                                    inputProps={{ maxLength: 14 }}
                                    onChange={(e) => field.onChange(removeSpaces(e.target.value))} // Enlève les espaces
                                  />
                                )}
                              />
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12} md={coBuyerClientType === 'person' ? 3 : 4}>
                          <Typography variant="body2" component="div" textAlign={'center'}>
                            {coBuyerClientType === 'person'
                              ? "Pièce d'identité*"
                              : "KBIS et Pièce d'identité du gérant*"}
                          </Typography>
                          {cgData ? (
                            cgData.coBuyerFilesUrls && <FilesOpener FilesUrls={cgData.coBuyerFilesUrls} />
                          ) : (
                            <FirebaseUploadComponent
                              onUploadComplete={(url) => coBuyerFilesUploadComplete(url)}
                              onDeleteComplete={(url) => coBuyerFilesDeleteComplete(url)}
                              uploadedUrls={coBuyerFilesUrls}
                            />
                          )}
                          {errors.coBuyerFilesUrls && typeof errors.coBuyerFilesUrls.message === 'string' && (
                            <Typography color="error" align="center">
                              {errors.coBuyerFilesUrls.message}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Grid container spacing={2} mt={2} justifyContent={'right'}>
                    <Grid item xs={5} sx={{ textAlign: 'right' }}>
                      {!isReadOnly && (
                        <Button
                          disabled={!isValid || isLoading}
                          onClick={handleConfirmClick}
                          variant="contained"
                          sx={{
                            marginTop: 1,
                            fontWeight: 600,
                            backgroundColor: theme.palette.saffron.main,
                            '&:hover': { backgroundColor: theme.palette.saffron.main },
                          }}
                        >
                          {isLoading && <CircularProgress sx={{ marginRight: 2 }} size={24} />} Soumettre la demande
                        </Button>
                      )}
                      {(!isValid ||
                        !watch('sellerGender') ||
                        !watch('buyerGender') ||
                        (cobuyer === 'yes' && !watch('coBuyerGender'))) && (
                        <Typography color="error" align="right">
                          Veuillez remplir tous les champs et/ou choisir les civilités
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Box>
        </Paper>
      </Container>
      {/* Modale de confirmation */}
      <Dialog open={isConfirmModalOpen} onClose={() => setIsConfirmModalOpen(false)} maxWidth="l">
        <DialogContent>
          <Typography mb={2} variant="body2">
            Êtes-vous sûr que toutes les informations sont correctes et que <b>votre dossier est complet</b> ?
          </Typography>
          <Typography variant="body2" sx={{ fontStyle: 'italic' }} mb={2}>
            Merci de bien vérifier les informations saisies car toute modification pourra entrainer des frais.
          </Typography>
          <Divider
            variant="fullWidth"
            component="hr"
            style={{
              marginLeft: 24,
              marginTop: 15,
              marginBottom: 15,
              backgroundColor: theme.palette.saffron.main,
              height: '2px',
              width: 'calc(100% - 24px)',
              border: 0,
            }}
          />
          <Typography variant="body2">
            Afin de pouvoir traiter vos dossier au plus vite, et que vos client reçoivent leur carte grise le plus
            rapidement possible,{' '}
            <b>
              veuillez prendre connaisssance de la liste des pièces obligatoires demandées par l’ANTS pour pouvoir
              émettre des déclarations de cession et Changement de titulaire :
            </b>
            <List>
              <ListItem>
                📄 Le certificat d’immatriculation anciennement{' '}
                <b>carte grise avec la mention cédée le .../../... et la signature</b> (Titulaire et cotitulaire)
              </ListItem>
              <ListItem>
                🆔 <b>Pièce d’identité</b> du nouveau titulaire en cours de validité
              </ListItem>
              <ListItem>
                🆔 <b>Pièce d’identité</b> du co-titulaire en cours de validité, si il y a un co-titulaire
              </ListItem>
              <ListItem>
                🚗 <b>Permis de conduire</b> pour les personnes physiques
              </ListItem>
              <ListItem>
                🏠 <b>Justificatif de domicile</b> de moins de 6 mois
              </ListItem>
              <ListItem>
                🛡️ <b>Attestation d’assurance</b> ou carte verte (indiquant immatriculation du véhicule assuré). // Les
                devis ne sont pas acceptés
              </ListItem>
              <ListItem>
                🔧 <b>Contrôle technique</b> favorable de moins de 6 mois ou <b>Contrôle technique non-favorable</b> de
                moins de 2 mois (si le véhicule à plus de 4 ans)
              </ListItem>
              <ListItem>
                📝 <b>Cerfa</b> de déclaration de cession complété par le vendeur et acquéreur
                <span style={{ margin: '0 4px' }}>
                  <Link
                    href="https://immatriculation.ants.gouv.fr/files/1e4341b6-4695-4f74-a51a-5ef962fba392/ef4ec059-07a7-4cc7-804f-e16944afeb5e/87e1c701-91dd-49de-86ed-52b7cb406dd0/cerfa_15776-01.pdf"
                    target="_blank"
                  >
                    (Cerfa 15776*01)
                  </Link>
                </span>
                original
              </ListItem>
              <ListItem>
                🖋️ <b>Mandat</b> à un professionnel pour effectuer les formalités d’immatriculation auprès du ministère
                de l’intérieur
                <span style={{ marginLeft: '4px' }}>
                  <Link href="https://www.service-public.fr/simulateur/calcul/13757" target="_blank">
                    (Cerfa 13757*03)
                  </Link>
                  complété au nom de Skipcar
                </span>
              </ListItem>
              <ListItem>
                📑 <b>Cerfa</b> de demande d’immatriculation d’un véhicule{' '}
                <span style={{ marginLeft: '4px' }}>
                  <Link
                    href="https://immatriculation.ants.gouv.fr/files/1e4341b6-4695-4f74-a51a-5ef962fba392/ef4ec059-07a7-4cc7-804f-e16944afeb5e/0b6477d5-86c9-43bb-bf65-08ade3d1ca37/cerfa_13750-05.pdf"
                    target="_blank"
                  >
                    (Cerfa 13750*05)
                  </Link>
                </span>
              </ListItem>
              <ListItem>
                📃 Le récépissé de la précédente déclaration d’achat uniquement, si véhicule acheté à un autre
                professionnel
              </ListItem>
            </List>
          </Typography>
        </DialogContent>
        <DialogActions style={{ marginLeft: 'auto', marginRight: '0' }}>
          <Button onClick={() => setIsConfirmModalOpen(false)}>Annuler</Button>
          <Button
            sx={{
              fontWeight: 700,
              margin: '16px auto',
              textAlign: 'center',
              color: 'black !important',
              backgroundColor: theme.palette.saffron.main,
              '&:hover': { backgroundColor: theme.palette.saffron.main },
            }}
            onClick={handleFinalConfirm}
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>

      <ErrorMessageSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={() => setSnackbarOpen(false)}
        severity={severity}
      />
      {taxData && agencyData && (
        <TaxModal
          open={modalOpen}
          handleClose={() => setModalOpen(false)}
          taxData={taxData}
          agencyData={agencyData}
          regPlate={regPlateValue}
          vin={vinValue}
          numFormule={numFormuleValue}
          registrationId={registrationId}
        />
      )}
    </>
  );
};

export default CGForm;

import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button,
} from '@mui/material';
import theme from '../../theme';

interface AgencyPerformanceProps {
  data: Array<{
    org_name: string;
    cotation_count: number;
    purchase_count: number;
    conversion_rate: number | null;
  }>;
  title: string;
}

export const AgencyPerformance: React.FC<AgencyPerformanceProps> = ({ data = [], title }) => {
  const [showAll, setShowAll] = useState(false);

  const safeData = Array.isArray(data) ? data : [];
  const displayedData = showAll ? safeData : safeData.slice(0, 5);

  const formatConversionRate = (rate: number | null): string => {
    if (rate === null || rate === undefined || isNaN(rate)) {
      return '0.00';
    }
    return Number(rate).toFixed(2);
  };

  return (
    <Card elevation={5} sx={{ padding: theme.spacing(2) }}>
      <CardContent>
        <Typography
          variant="body1"
          sx={{ textAlign: 'left', color: title.includes('Top') ? theme.palette.saffron.main : theme.palette.red.main }}
        >
          {title}
        </Typography>
        <TableContainer>
          <Table>
            <TableBody>
              {displayedData.map((row) => (
                <TableRow key={row.org_name}>
                  <TableCell
                    sx={{
                      borderBottom: 0,
                      paddingBottom: 1,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography sx={{ fontWeight: '600', fontSize: '14px' }}>{row.org_name}</Typography>
                    <Typography sx={{ fontWeight: '600', fontSize: '14px' }}>
                      Cotations : {row.cotation_count || 0} | Achats : {row.purchase_count || 0} | Transfo :{' '}
                      {formatConversionRate(row.conversion_rate)}%
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {safeData.length > 5 && (
          <Button
            sx={{
              marginLeft: 1,
              fontWeight: 700,
              fontSize: 12,
              marginTop: theme.spacing(4),
              height: 33,
              color: 'black !important',
              backgroundColor: theme.palette.saffron.main,
              '&:hover': { backgroundColor: theme.palette.saffron.main },
            }}
            onClick={() => setShowAll(!showAll)}
          >
            {showAll ? 'Voir moins' : 'Voir plus'}
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

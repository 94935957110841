import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  capitalize,
} from '@mui/material';
import { priceFormatter } from '../../utils';

interface SalesDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  data: Array<{
    regplate: string;
    pipedrive_deal_id: string;
    make: string;
    model: string;
    ext_url: string;
    sale_amount: number;
    net_margin: number;
  }>;
}

const stickyCellStyle = {
  background: '#262626',
  fontWeight: 'bold',
  minWidth: 50,
  color: 'white',
  position: 'sticky',
  top: 0,
  zIndex: 100,
};

const SalesDetailsDialog: React.FC<SalesDetailsDialogProps> = ({ open, onClose, title, data = [] }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={stickyCellStyle}>Immatriculation</TableCell>
              <TableCell sx={stickyCellStyle}>Pipedrive Deal ID</TableCell>
              <TableCell sx={stickyCellStyle}>Marque</TableCell>
              <TableCell sx={stickyCellStyle}>Modèle</TableCell>
              <TableCell sx={stickyCellStyle}>Montant de la vente</TableCell>
              <TableCell sx={stickyCellStyle}>Marge</TableCell>
              <TableCell sx={stickyCellStyle}>URL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(1).map((sale, index) => (
              <TableRow key={index}>
                <TableCell>{sale.regplate}</TableCell>
                <TableCell>{sale.pipedrive_deal_id}</TableCell>
                <TableCell>{capitalize(sale.make)}</TableCell>
                <TableCell>{sale.model}</TableCell>
                <TableCell>{priceFormatter.format(sale.sale_amount)}</TableCell>
                <TableCell>{priceFormatter.format(sale.net_margin)}</TableCell>
                <TableCell>
                  <a
                    href={`${sale.ext_url}&arbitrator`}
                    style={{ color: 'black' }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Lien
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

export default SalesDetailsDialog;

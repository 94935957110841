import React, { useState } from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useDailyBuyingStats } from '../../hooks/useApiQueries';
import theme from '../../theme';

const stickyCellStyle = {
  background: '#262626',
  fontWeight: 'bold',
  minWidth: 50,
  color: 'white',
  position: 'sticky',
  top: 0,
  zIndex: 100,
};

interface DailyBuyingStatsTableProps {
  franchise?: string;
  orgName?: string;
}

interface VehicleDetail {
  pipedrive_deal_id: string;
  registration_plate: string;
  ext_url: string;
}

interface DailyBuyingStats {
  date: string;
  quotations: number;
  purchases: number;
  transformation_rate: number | string;
  details: VehicleDetail[];
}

const DailyBuyingStatsTable: React.FC<DailyBuyingStatsTableProps> = ({ franchise, orgName }) => {
  const { data: stats, isLoading } = useDailyBuyingStats(franchise, orgName);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState<VehicleDetail[]>([]);

  if (isLoading || !stats) return null;

  const handleRowClick = (details: VehicleDetail[]) => {
    if (details && details.length > 0) {
      const uniqueDetails = filterUniqueDetails(details);
      setSelectedDetails(uniqueDetails);
      setDialogOpen(true);
    }
  };

  const filterUniqueDetails = (details: VehicleDetail[]): VehicleDetail[] => {
    const seen = new Set();
    return details.filter((detail) => {
      if (seen.has(detail.pipedrive_deal_id)) {
        return false;
      }
      seen.add(detail.pipedrive_deal_id);
      return true;
    });
  };

  const dailyStats = stats as DailyBuyingStats[];

  return (
    <>
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ background: '#262626' }}>
              <TableCell sx={{ fontWeight: 600, color: 'white' }}>Date</TableCell>
              <TableCell sx={{ fontWeight: 600, color: 'white' }}>Achats</TableCell>
              <TableCell sx={{ fontWeight: 600, color: 'white' }}>Cotations</TableCell>
              <TableCell sx={{ fontWeight: 600, color: 'white' }}>Taux de transfo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dailyStats.map((stat: DailyBuyingStats, index: number) => (
              <TableRow
                className="tableRow"
                key={index}
                onClick={() => handleRowClick(stat.details)}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell className="tableCell">
                  {format(new Date(stat.date), 'dd MMM yyyy', { locale: fr })}
                </TableCell>
                <TableCell className="tableCell">{stat.purchases}</TableCell>
                <TableCell className="tableCell">{stat.quotations}</TableCell>
                <TableCell className="tableCell">
                  <Typography
                    component="span"
                    sx={{
                      color: Number(stat.transformation_rate) > 0 ? 'green' : theme.palette.error.main,
                      fontWeight: 'bold',
                    }}
                  >
                    {Number(stat.transformation_rate).toFixed(2)}%
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Détails de l'achat</DialogTitle>
        <DialogContent>
          <Paper sx={{ marginTop: 2 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={stickyCellStyle}>Pipedrive Deal Id</TableCell>
                  <TableCell sx={stickyCellStyle}>Plaque</TableCell>
                  <TableCell sx={stickyCellStyle}>Lien</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedDetails.map((detail, index) => (
                  <TableRow key={index}>
                    <TableCell>{detail.pipedrive_deal_id}</TableCell>
                    <TableCell>{detail.registration_plate}</TableCell>
                    <TableCell>
                      <a
                        style={{ color: 'black' }}
                        href={`${detail.ext_url}&arbitrator`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Voir la fiche
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DailyBuyingStatsTable;

// packages/frontend/src/components/PrivateRoute.tsx
import { ReactNode, useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom'; // Importez useLocation
import { useAuth } from './AuthContext';
import { CircularProgress, Grid } from '@mui/material';
import useFromExternalStore from '../stores/FromExternalStore';

interface PrivateRouteProps {
  children: ReactNode;
}

// Définir les routes autorisées pour chaque rôle
/*
const allowedRoutes = {
  god: ['/', '/deals-list', '/settings', '/cotation', '/companies-list'],
  head: ['/', '/deals-list', '/settings', '/cotation'],
  agency: ['/', '/deals-list', '/settings', '/cotation'],
};
*/

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children = false }) => {
  const { currentUser, isInitializing, isFetchingPipedriveData } = useAuth();
  const [showLoader, setShowLoader] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  const userRole = currentUser?.data?.data?.role;
  const path = location.pathname;

  const isFromExternal = useFromExternalStore((state) => state.fromExternal);
  const setFromExternal = useFromExternalStore((state) => state.setFromExternal);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 5000); // Attendre, par exemple, 5 secondes

    return () => clearTimeout(timer); // Nettoyage
  }, []);

  useEffect(() => {
    if (isFromExternal && !location.search.includes('from=external') && location.pathname !== '/login') {
      // Si l'utilisateur est passé d'un accès externe à un non-externe, redirigez vers la page d'accueil externe
      setFromExternal(false);
      navigate('/cotation?from=external', { replace: true });
    }
  }, [location.search, navigate]);

  // Si on est en train de charger (vérification de l'authentification), affichez le loader
  /*
  if (isInitializing || isFetchingPipedriveData) {
    return (
      <Grid container direction="column" justifyContent="center" alignItems="center" className="formSubmitLoading">
        <Typography sx={{ marginBottom: 2 }} variant="body1">
          Chargement
        </Typography>
        <CircularProgress size={70} />
      </Grid>
    );
  }
  */
  /*
  if (!currentUser) {
    console.log('isInitializing', isInitializing); // true
    console.log('isFetchingPipedriveData', isFetchingPipedriveData); // true
    // => Comment attendre qu'ils passent à false  au bout de qq secondes, puis si ce n'est pas OK, là on redirige vers /login ?
    return path !== '/login' ? <Navigate to="/login" replace /> : null;
  }
  */

  if (path === '/login') {
    return <Navigate to="/" replace />;
  }

  if (isFromExternal) {
    if (path === '/login') {
      return children; // Permettre de rester sur la page /login
    } else if (path === '/cotation' && location.search === '?from=external') {
      return children;
    } else {
      return <Navigate to="/cotation?from=external" replace />;
    }
  }

  if (isFromExternal && location.pathname !== '/cotation') {
    return <Navigate to="/cotation?from=external" replace />;
  }

  // Add your role-based routing logic here
  if (userRole === 'god' || userRole === 'arbitrator') {
    return children;
  } else if (userRole === 'head') {
    // Add routes that 'head' users should have access to
    return children;
  } else if (userRole === 'agency') {
    // Add routes that 'agency' users should have access to
    if (
      path === `/form-cg` ||
      path === `/list-cg` ||
      path === `/deals-list` ||
      path === `/settings` ||
      path === `/cotation` ||
      path === `/`
    ) {
      return children;
    } else {
      // Redirect 'agency' users to a default route if they try to access a restricted route
      return <Navigate to="/" replace />;
    }
  }

  if (!currentUser && !isFromExternal) {
    if (isInitializing || isFetchingPipedriveData) {
      if (showLoader) {
        // Affichez ici l'indicateur de chargement (par exemple, CircularProgress)
        return (
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Grid>
        );
      } else {
        // Redirection vers la page de connexion après l'expiration du délai
        return path !== '/login' ? <Navigate to="/login" replace /> : null;
      }
    }
    // Redirection immédiate si l'état n'est pas en cours d'initialisation ou de récupération des données
    return path !== '/login' ? <Navigate to="/login" replace /> : null;
  }

  // Default redirect for any other case
  return <Navigate to="/" replace />;

  /*
  if (!currentUser) {
    // Si l'utilisateur n'est pas authentifié et n'est pas sur la page de connexion, redirigez-le vers la page de connexion
    if (location.pathname !== '/login') {
      return <Navigate to="/login" replace />;
    }
    // Si l'utilisateur n'est pas authentifié mais est déjà sur la page de connexion, laissez-le y rester
    return null;
  }

  // Si l'utilisateur est authentifié et est sur la page de connexion, redirigez-le vers la page d'accueil
  if (location.pathname === '/login') {
    return <Navigate to="/" replace />;
  }

  // Vérifier si l'utilisateur est autorisé à accéder à la route
  if (!allowedRoutes[userRole].includes(path)) {
    console.log(`${userRole} user is not allowed to access ${path}`);
    return <Navigate to="/" replace />; // Rediriger vers la page d'accueil ou une page d'erreur
  }

  // Si l'utilisateur est authentifié et n'est pas sur la page de connexion, affichez les enfants
  return children;
  */
};

export default PrivateRoute;

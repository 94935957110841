import React from 'react';
import { Grid, Typography, Card, Box, Tooltip as MuiTooltip } from '@mui/material';
import { useMonthlyStats } from '../../hooks/useApiQueries';
import { priceFormatter } from '../../utils';
import theme from '../../theme';

interface MonthlyStatsOverviewProps {
  franchise?: string;
  orgName?: string;
}

const MonthlyStatsOverview: React.FC<MonthlyStatsOverviewProps> = ({ franchise, orgName }) => {
  const { data: stats, isLoading } = useMonthlyStats(franchise, orgName);

  if (isLoading || !stats) return null;

  const calculatePercentageChange = (current: number, previous: number): string => {
    if (previous === 0) return '+∞';
    const change = ((current - previous) / previous) * 100;
    return `${change > 0 ? '+' : ''}${change.toFixed(1)}%`;
  };

  const getVariationColor = (current: number, previous: number): string => {
    if (previous === 0) return theme.palette.warning.main;
    return current >= previous ? theme.palette.warning.main : theme.palette.error.main;
  };

  const statsConfig = [
    {
      label: 'Cotations',
      count: {
        current: stats.currentMonth.totalCotations,
        previous: stats.previousMonth.totalCotations,
        label: 'Nombre',
      },
      value: {
        current: stats.currentMonth.totalCotationsPotentialValue,
        previous: stats.previousMonth.totalCotationsPotentialValue,
        label: 'Valeur potentielle',
      },
      tooltip: 'Nombre et valeur potentielle des cotations du mois',
    },
    {
      label: 'Achats',
      count: {
        current: stats.currentMonth.totalBuying,
        previous: stats.previousMonth.totalBuying,
        label: 'Nombre',
      },
      value: {
        current: stats.currentMonth.totalBuyingValue,
        previous: stats.previousMonth.totalBuyingValue,
        label: 'Valeur',
      },
      tooltip: 'Nombre et valeur des achats du mois',
    },
    {
      label: 'Ventes',
      count: {
        current: stats.currentMonth.totalSelling,
        previous: stats.previousMonth.totalSelling,
        label: 'Nombre',
      },
      value: {
        current: stats.currentMonth.totalSellingValue,
        previous: stats.previousMonth.totalSellingValue,
        label: 'Valeur',
      },
      netMargin: {
        current: stats.currentMonth.totalNetMarginValue,
        previous: stats.previousMonth.totalNetMarginValue,
        label: 'Marge nette',
      },
      tooltip: 'Nombre, valeur et marge nette des ventes du mois',
    },
  ];

  const MetricComparison = ({
    current,
    previous,
    label,
    isPrice = false,
  }: {
    current: number;
    previous: number;
    label: string;
    isPrice?: boolean;
  }) => (
    <Box sx={{ marginBottom: 1 }}>
      <Typography variant="body1" sx={{ fontSize: '12px' }}>
        {label}
      </Typography>
      <Box>
        <Typography variant="h6" fontWeight="bold">
          {isPrice ? priceFormatter.format(current) : current}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: getVariationColor(current, previous),
            fontSize: '12px',
          }}
        >
          <b>
            {calculatePercentageChange(current, previous)} / {isPrice ? priceFormatter.format(previous) : previous} vs
            m-1
          </b>
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Grid container spacing={2} sx={{ marginBottom: theme.spacing(4) }}>
      {statsConfig.map((stat) => (
        <Grid item xs={12} sm={stat.label === 'Achats' ? 3 : stat.label === 'Ventes' ? 5 : 4} key={stat.label}>
          <MuiTooltip title={stat.tooltip} arrow placement="top">
            <Card elevation={5} sx={{ padding: theme.spacing(2) }}>
              <Typography variant="h6" sx={{ marginBottom: 2 }}>
                {stat.label}
              </Typography>

              <Box display="flex" flexDirection="row" gap={1} justifyContent="space-between">
                <MetricComparison
                  current={stat.count.current}
                  previous={stat.count.previous}
                  label={stat.count.label}
                />
                <MetricComparison
                  current={stat.value.current}
                  previous={stat.value.previous}
                  label={stat.value.label}
                  isPrice={true}
                />
                {stat.netMargin && (
                  <MetricComparison
                    current={stat.netMargin.current}
                    previous={stat.netMargin.previous}
                    label={stat.netMargin.label}
                    isPrice={true}
                  />
                )}
              </Box>
            </Card>
          </MuiTooltip>
        </Grid>
      ))}
    </Grid>
  );
};

export default MonthlyStatsOverview;

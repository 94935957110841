import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  capitalize,
} from '@mui/material';

interface PurchasesDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  data: Array<{ regplate: string; pipedrive_deal_id: string; make: string; model: string; ext_url: string }>;
}

const stickyCellStyle = {
  background: '#262626',
  fontWeight: 'bold',
  minWidth: 50,
  color: 'white',
  position: 'sticky',
  top: 0,
  zIndex: 100,
};

const PurchasesDetailsDialog: React.FC<PurchasesDetailsDialogProps> = ({ open, onClose, title, data = [] }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={stickyCellStyle}>Immatriculation</TableCell>
              <TableCell sx={stickyCellStyle}>Pipedrive Deal ID</TableCell>
              <TableCell sx={stickyCellStyle}>Marque</TableCell>
              <TableCell sx={stickyCellStyle}>Modèle</TableCell>
              <TableCell sx={stickyCellStyle}>URL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((purchase, index) => (
              <TableRow key={index}>
                <TableCell>{purchase.regplate}</TableCell>
                <TableCell>{purchase.pipedrive_deal_id}</TableCell>
                <TableCell>{capitalize(purchase.make)}</TableCell>
                <TableCell>{purchase.model}</TableCell>
                <TableCell>
                  <a
                    href={`${purchase.ext_url}&arbitrator`}
                    style={{ color: 'black' }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Lien
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

export default PurchasesDetailsDialog;

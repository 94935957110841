import React, { useState } from 'react';
import { Grid, Typography, Card, CardContent, Tooltip, Box } from '@mui/material';
import { useAgencyStats } from '../../hooks/useApiQueries';
import theme from '../../theme';
import AgencyDetailsDialog from './AgencyDetailsDialog';

const AgencyStatsOverview: React.FC = () => {
  const { data: stats } = useAgencyStats();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedAgencies, setSelectedAgencies] = useState<string[]>([]);
  const [dialogTitle, setDialogTitle] = useState('');

  const handleStatsClick = (label: string, agencies: string[]) => {
    setSelectedAgencies(agencies);
    setDialogTitle(`${label} - Liste des agences`);
    setDialogOpen(true);
  };

  const positiveStatsConfig = [
    {
      label: 'Agences actives',
      value: stats?.activeAgencies?.count || 0,
      tooltip: "Nombre d'agences actives dans le mois",
      agencies: stats?.activeAgencies?.agencies || [],
    },
    {
      label: 'Réactivations',
      value: stats?.reactivatedAgencies?.count || 0,
      tooltip: "Nombre d'agences qui ont déjà travaillé dans l'année et qui retravaillent dans le mois en cours",
      agencies: stats?.reactivatedAgencies?.agencies || [],
    },
    {
      label: 'Nouvelles activations',
      value: stats?.newActivations?.count || 0,
      tooltip: "Nombre d'agences qui passent leur première reprise dans le mois en cours",
      agencies: stats?.newActivations?.agencies || [],
    },
  ];

  const negativeStatsConfig = [
    {
      label: 'Agences inactives',
      value: stats?.inactiveAgencies?.count || 0,
      tooltip: "Nombre d'agences qui n'ont pas fait de cotations dans le mois en cours",
      agencies: stats?.inactiveAgencies?.agencies || [],
    },
    {
      label: 'Non réactivées',
      value: stats?.nonReactivatedAgencies?.count || 0,
      tooltip: "Nombre d'agences qui ont fait des cotations dans le mois précédent mais pas dans le mois en cours",
      agencies: stats?.nonReactivatedAgencies?.agencies || [],
    },
  ];

  return (
    <Box sx={{ marginTop: theme.spacing(8), width: '100%', maxWidth: 1200, mx: 'auto' }}>
      <Grid container spacing={2} sx={{ marginBottom: theme.spacing(2) }}>
        {positiveStatsConfig.map((stat) => (
          <Grid item xs={12} md={4} key={stat.label}>
            <Tooltip title={stat.tooltip} arrow placement="top">
              <Card
                elevation={2}
                sx={{ cursor: 'pointer' }}
                onClick={() => handleStatsClick(stat.label, stat.agencies)}
              >
                <CardContent>
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: 'center',
                      color: 'black',
                      marginBottom: 1,
                    }}
                  >
                    {stat.label}
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      textAlign: 'center',
                      color: theme.palette.saffron.main,
                      fontWeight: 'bold',
                    }}
                  >
                    {stat.value}
                  </Typography>
                </CardContent>
              </Card>
            </Tooltip>
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2} sx={{ marginBottom: theme.spacing(4) }}>
        {negativeStatsConfig.map((stat) => (
          <Grid item xs={12} md={6} key={stat.label}>
            <Tooltip title={stat.tooltip} arrow placement="top">
              <Card
                elevation={2}
                sx={{ cursor: 'pointer' }}
                onClick={() => handleStatsClick(stat.label, stat.agencies)}
              >
                <CardContent>
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: 'center',
                      color: 'black',
                      marginBottom: 1,
                    }}
                  >
                    {stat.label}
                  </Typography>
                  <Typography
                    variant="h4"
                    sx={{
                      textAlign: 'center',
                      color: theme.palette.error.main,
                      fontWeight: 'bold',
                    }}
                  >
                    {stat.value}
                  </Typography>
                </CardContent>
              </Card>
            </Tooltip>
          </Grid>
        ))}
      </Grid>

      <AgencyDetailsDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={dialogTitle}
        agencies={selectedAgencies}
      />
    </Box>
  );
};

export default AgencyStatsOverview;

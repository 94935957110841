import React, { useEffect, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';

import { makeAuthenticatedApiCall } from '../../apiHelper';
// Import the new component
import theme from '../../theme';
import { formatDateToDDMMYYYY } from '../../utils';
import AgencyInfoDialog from './AgencyInfoDialog';

const stickyCellStyle = {
  background: '#262626',
  fontWeight: 'bold',
  minWidth: 50,
  color: 'white',
  position: 'sticky',
  top: 0,
  zIndex: 100,
};

const CGPendingInvoicesList = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rows, setRows] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [selectedClientId, setSelectedClientId] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await makeAuthenticatedApiCall('get', '/api/qonto/pending-invoices');
        setRows((response.data as any[]) || []);
      } catch (error) {
        console.error('Error fetching pending invoices:', error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  const handleGenerateInvoices = async () => {
    setIsGenerating(true);
    try {
      const response = await makeAuthenticatedApiCall('get', '/api/qonto/generate-pending-invoices');
      const data = (response.data as { message: string }).message;
      if (data) {
        setSnackbarMessage(data);
        setSnackbarSeverity('success');
      } else {
        setSnackbarMessage('Pas de factures en attente de traitement.');
        setSnackbarSeverity('success');
      }
    } catch (error) {
      console.error('Error generating invoices:', error);
      setSnackbarMessage('Échec de la génération des factures.');
      setSnackbarSeverity('error');
    }
    setIsGenerating(false);
    setSnackbarOpen(true);
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpen = (clientId: string) => {
    setSelectedClientId(clientId);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectedClientId(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (isLoading) {
    return (
      <Grid container direction="column" justifyContent="center" alignItems="center" marginTop={2}>
        <Typography sx={{ marginBottom: 2 }} variant="body1">
          Chargement
        </Typography>
        <CircularProgress size={70} />
      </Grid>
    );
  }

  return (
    <Container maxWidth={false}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Button
          variant="contained"
          color="primary"
          startIcon={<ArrowBackIcon />}
          onClick={() => window.history.back()} // Use history.back() to go back
          sx={{
            fontWeight: 600,
            marginTop: 4,
            height: 33,
            color: 'black !important',
            backgroundColor: theme.palette.saffron.main,
            '&:hover': { backgroundColor: theme.palette.saffron.main },
          }}
        >
          Retour
        </Button>
        <Button
          variant="contained"
          sx={{
            marginBottom: 2,
            marginTop: 4,
            fontWeight: 600,
            height: 33,
            color: 'black !important',
            backgroundColor: theme.palette.saffron.main,
            '&:hover': { backgroundColor: theme.palette.saffron.main },
          }}
          onClick={handleGenerateInvoices}
          disabled={isGenerating}
        >
          {isGenerating ? <CircularProgress size={24} /> : 'Générer les factures'}
        </Button>
      </Box>
      <Paper sx={{ marginTop: 5, padding: 0 }} elevation={3}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={stickyCellStyle}>Client ID</TableCell>
                <TableCell sx={stickyCellStyle}>Pipedrive Deal ID</TableCell>
                <TableCell sx={stickyCellStyle}>Pipeline Letter</TableCell>
                <TableCell sx={stickyCellStyle}>VAT Rate</TableCell>
                <TableCell sx={stickyCellStyle}>Selling Price</TableCell>
                <TableCell sx={stickyCellStyle}>Reg Plate</TableCell>
                <TableCell sx={stickyCellStyle}>Created At</TableCell>
                <TableCell sx={stickyCellStyle}>Processed</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                <TableRow key={`${row.id}_${index}`}>
                  <TableCell onClick={() => handleOpen(row.client_id)} style={{ cursor: 'pointer', fontWeight: '600' }}>
                    {row.client_id}
                  </TableCell>
                  <TableCell>{row.pipedrive_deal_id}</TableCell>
                  <TableCell>{row.pipeline_letter}</TableCell>
                  <TableCell>{row.vat_rate}</TableCell>
                  <TableCell>{row.selling_price}</TableCell>
                  <TableCell>{row.reg_plate}</TableCell>
                  <TableCell>{formatDateToDDMMYYYY(row.created_at)}</TableCell>
                  <TableCell>{row.processed ? 'Yes' : 'No'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <AgencyInfoDialog clientId={selectedClientId} open={dialogOpen} onClose={handleClose} />
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CGPendingInvoicesList;

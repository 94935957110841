import { Link, Typography } from '@mui/material';

interface RegistrationLinkProps {
  id: number;
  value: string;
  brand: string;
  fuel: string;
  page: number;
  rowsPerPage: number;
  dealExists: boolean;
}

const RegistrationLink: React.FC<RegistrationLinkProps> = ({ id, value, brand, fuel, dealExists }) => {
  return dealExists ? (
    <Link
      sx={{ fontWeight: 700 }}
      target="_blank"
      href={`/car-deal/${id}?make=${brand.replace(/\s+/g, '_').toLowerCase()}&fuel=${encodeURIComponent(fuel)}`}
    >
      {value}
    </Link>
  ) : (
    <Typography variant="body2" sx={{ fontSize: 16, fontWeight: 700 }}>
      {value}
    </Typography>
  );
};

export default RegistrationLink;

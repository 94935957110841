import React from 'react';
import { Typography } from '@mui/material';

interface DealSenderStatusMessageProps {
  sender: string | undefined;
}

const DealSenderStatusMessage: React.FC<DealSenderStatusMessageProps> = ({ sender }) => {
  if (sender === 'agency') {
    return (
      <Typography sx={{ marginTop: 1, fontSize: 16 }} align="center" variant="body2" className="MuiTypographyTable">
        ❌ Cotation clôturée
      </Typography>
    );
  } else if (sender === 'evo') {
    return (
      <Typography sx={{ marginTop: 1, fontSize: 16 }} align="center" variant="body2" className="MuiTypographyTable">
        🕒 En attente d'informations
      </Typography>
    );
  }
  return null;
};

export default DealSenderStatusMessage;

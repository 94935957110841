// packages/frontend/src/components/CreateCotation/FirebaseUploadComponent.tsx
import { FC, useState, useEffect, useCallback } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { Snackbar, Button, Grid, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Dropzone from './DropZone';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { LinearProgress } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { isPdf, extractFileName } from '../../utils';

const storage = getStorage();

interface FirebaseUploadComponentProps {
  onUploadComplete?: (url: string) => void;
  onDeleteComplete?: (url: string) => void;
  uploadedUrls?: string[];
  isRequired?: boolean;
  onValidate?: (isValid: boolean) => void;
  source?: string;
}

const FirebaseUploadComponent: FC<FirebaseUploadComponentProps> = ({
  onUploadComplete,
  onDeleteComplete,
  uploadedUrls,
  isRequired = false,
  onValidate,
  source,
}) => {
  const [urls, setUrls] = useState<string[]>(uploadedUrls || []);
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [progress, setProgress] = useState<number>(0);

  const auth = getAuth();
  const currentUser = auth.currentUser;

  const handleError = (error: Error, message: string, extraInfo: Record<string, unknown> = {}) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
    console.error(message, error);

    Sentry.withScope((scope) => {
      scope.setExtras(extraInfo);
      if (currentUser) {
        scope.setUser({ email: currentUser.email ?? '' });
      }
      Sentry.captureException(error);
    });
  };

  useEffect(() => {
    if (isRequired && onValidate) {
      const isValid = urls?.length > 0;
      onValidate(isValid);
    }
  }, [urls, isRequired, onValidate]);

  const handleDelete = async (urlToDelete: string): Promise<void> => {
    const fileName = urlToDelete.split('/').pop()?.split('?')[0];
    if (fileName) {
      const storageRef = ref(storage, `${decodeURIComponent(fileName)}`);
      try {
        await deleteObject(storageRef);
        setUrls((prevUrls) => prevUrls.filter((url) => url !== urlToDelete));
        console.log(`Image deleted: ${urlToDelete}`);
        onDeleteComplete?.(urlToDelete);
      } catch (error: any) {
        const errorMessage = "Erreur lors de la suppression de l'image";
        const extraInfo = { url: urlToDelete, fileName: fileName };
        handleError(error, errorMessage, extraInfo);
      }
    }
  };

  const handleClose = (): void => {
    setSnackbarOpen(false);
  };

  const handleFilesAdded = useCallback(
    async (files: File[]) => {
      setLoading(true);
      files.forEach((file) => {
        if (isPdf(file)) {
          uploadFile(file, source); // Pass source here
        } else {
          const formData = new FormData();
          formData.append('image', file);

          axios
            .post('/api/img/resize', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              responseType: 'blob',
            })
            .then((response) => {
              const newFile = new File([response.data], file.name, { type: file.type });
              uploadFile(newFile, source); // Pass source here
            })
            .catch((error) => {
              const errorMessage = "Erreur lors du redimensionnement de l'image";
              const extraInfo = { fileSize: file.size, fileType: file.type, fileName: file.name };
              handleError(error, errorMessage, extraInfo);
            });
        }
      });
    },
    [source],
  ); // Add source to dependencies

  function getStorageRef(file: File, source?: string) {
    const uniqueId = Date.now().toString(36) + Math.random().toString(36).substr(2, 5);
    const fileExtension = file.name.split('.').pop();

    if (!fileExtension) {
      throw new Error('File extension is missing');
    }

    const fileNameWithoutExtension = file.name
      .slice(0, -(fileExtension.length + 1))
      .replace(/\s+/g, '_')
      .replace(/[^a-zA-Z0-9_\-.]/g, '');

    if (!isPdf(file.name)) {
      const randomFileName = `${uniqueId}.${fileExtension}`;
      return ref(storage, `images/${source ?? currentUser?.uid}/${randomFileName}`);
    } else {
      const randomSuffix = Math.floor(Math.random() * 90000) + 10000; // Génère un nombre aléatoire de 5 chiffres
      const randomFileName = `${fileNameWithoutExtension}_${randomSuffix}.${fileExtension}`;
      return ref(storage, `images/${source ?? currentUser?.uid}/${randomFileName}`);
    }
  }

  const uploadFile = (file: File, source?: string): void => {
    try {
      const storageRef = getStorageRef(file, source); // Pass source here
      const uploadTask = uploadBytesResumable(storageRef, file);

      const uploadPromise = new Promise<string>((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(progress);
          },
          (error) => {
            console.error('Upload error:', error);
            reject(error);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(downloadURL);
            } catch (error) {
              console.error('Error getting download URL:', error);
              reject(error);
            }
          },
        );
      });

      uploadPromise
        .then((downloadURL) => {
          setUrls((prevUrls) => [...prevUrls, downloadURL]);
          setLoading(false);
          onUploadComplete?.(downloadURL);
        })
        .catch((error) => {
          const errorMessage = `Erreur lors de l'upload de l'image : ${error.message}`;
          const extraInfo = { fileSize: file.size, fileType: file.type };
          handleError(error, errorMessage, extraInfo);
        });
    } catch (error) {
      if (error instanceof Error) {
        const errorMessage = `Erreur lors de l'upload de l'image : ${error.message}`;
        const extraInfo = {
          fileSize: file.size,
          fileType: file.type,
          fileName: file.name,
        };
        handleError(error, errorMessage, extraInfo);
      }
    }
  };

  const checkImageUrl = async (url: string): Promise<boolean> => {
    try {
      const response = await fetch(url, { method: 'HEAD' });
      return response.ok;
    } catch {
      return false;
    }
  };

  const handleImageError = async (url: string): Promise<void> => {
    const isValid = await checkImageUrl(url);
    if (!isValid) {
      setUrls((prevUrls) => prevUrls.filter((u) => u !== url));
      regenerateUrl(url);
    }
  };

  const regenerateUrl = async (oldUrl: string): Promise<void> => {
    try {
      const fileName = oldUrl.split('/').pop()?.split('?')[0];
      if (fileName) {
        const storageRef = ref(storage, `${decodeURIComponent(fileName)}`);

        await deleteObject(storageRef);

        const file = await fetch(oldUrl)
          .then((res) => res.blob())
          .then((blob) => new File([blob], fileName));
        const newStorageRef = getStorageRef(file);
        const uploadTask = uploadBytesResumable(newStorageRef, file);

        const newDownloadURL = await new Promise<string>((resolve, reject) => {
          uploadTask.on(
            'state_changed',
            null,
            (error) => reject(error),
            async () => {
              try {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                resolve(downloadURL);
              } catch (error) {
                reject(error);
              }
            },
          );
        });

        setUrls((prevUrls) => prevUrls.map((url) => (url === oldUrl ? newDownloadURL : url)));
        onUploadComplete?.(newDownloadURL);
      }
    } catch (error: any) {
      const errorMessage = "Erreur lors de la régénération de l'URL";
      const extraInfo = { oldUrl, fileName: oldUrl.split('/').pop() };
      handleError(error, errorMessage, extraInfo);
    }
  };

  const renderPreview = (url: string, index: number) => {
    if (isPdf(url)) {
      const fileName = extractFileName(url);
      return (
        <Grid
          style={{
            height: '100%',
            overflow: 'hidden',
            objectPosition: 'center',
            objectFit: 'cover',
            background: 'rgba(253, 187, 95, 0.4)',
            width: 190,
            maxWidth: '100%',
            flex: 'inherit',
            position: 'relative',
          }}
          item
          xs={12}
          sm={6}
          md={4}
          key={index}
        >
          <PictureAsPdfIcon
            style={{
              fontSize: 10,
              height: 'auto',
              width: '70px',
              color: 'black',
              marginTop: '10px',
              marginLeft: '10px',
            }}
          />
          <Typography
            sx={{
              fontFamily: 'Urbanist',
              fontSize: '8px',
              textDecoration: 'none',
              color: 'black',
              textAlign: 'center',
              position: 'absolute',
              bottom: 10,
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            {fileName.split('/').pop()}
          </Typography>
        </Grid>
      );
    } else {
      return <img src={url} alt="" width="190" onError={() => handleImageError(url)} />;
    }
  };

  return (
    <div>
      <Dropzone onFilesAdded={handleFilesAdded} />
      {loading && (
        <LinearProgress
          sx={{
            marginTop: 5,
            height: '10px',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#FDBB5F',
            },
          }}
          variant="determinate"
          value={progress}
        />
      )}
      <Grid item xs={12} sx={{ marginTop: 2, display: 'flex', flexFlow: 'row wrap' }}>
        {urls.map((url, index) => (
          <div
            key={index}
            style={{
              overflow: 'hidden',
              height: 190,
              width: 190,
              position: 'relative',
              marginRight: 13,
              marginBottom: 13,
              border: '1px solid lightgray',
            }}
          >
            <a href={url} target="_blank" rel="noopener noreferrer">
              {renderPreview(url, index)}
            </a>
            <Button
              style={{ position: 'absolute', top: 0, right: 0, background: 'red', color: 'white' }}
              onClick={() => handleDelete(url)}
            >
              X
            </Button>
          </div>
        ))}
      </Grid>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
        <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="error">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default FirebaseUploadComponent;

import { useState, useEffect } from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Typography,
  Box,
  Grid,
  Container,
  Tab,
  Tabs,
  Button,
} from '@mui/material';
import { useArbitratorDeals } from '../hooks/useApiQueries';
import { DBDeal, ArbitratorDealsListProps } from '../types/deal';
import ArbitratorDeal from './ArbitratorDealsList/ArbitratorDeal';
import { useAuth } from './AuthContext';
import theme from '../theme';
import axios from 'axios';
import { makeAuthenticatedApiCall } from '../apiHelper';

const ArbitratorDealsList: React.FC<ArbitratorDealsListProps> = () => {
  const [isRendering, setIsRendering] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [tabValue, setTabValue] = useState(0);
  const [filteredDeals, setFilteredDeals] = useState<DBDeal[]>([]);
  const [dealsInfo, setDealsInfo] = useState({ treaded: 0, toBeTreated: 0, awaitingSupervision: 0 });
  const [dealsMap, setDealsMap] = useState<Map<number, DBDeal>>(new Map());

  const { currentUser } = useAuth();
  const userRole = currentUser?.data?.data?.role;
  const userEmail = currentUser?.email;
  const {
    data: arbitratorDeals,
    isLoading: arbitratorDealsIsLoading,
    error,
    refetch: refetchArbitratorDeals,
  } = useArbitratorDeals(tabValue === 0 ? undefined : tabValue.toString(), tabValue === 0 ? 75 : undefined, true);

  useEffect(() => {
    const fetchDealsFromDB = async (dealIds: number[]) => {
      setIsRendering(true);
      try {
        const dealsResponse = await makeAuthenticatedApiCall('post', '/api/deals-by-ids', { ids: dealIds });
        // Sort the deals by add_time in descending order
        const sortedDeals = (dealsResponse.data as DBDeal[])
          .filter((deal: DBDeal) => deal.add_time !== null)
          .sort((a: DBDeal, b: DBDeal) => new Date(b.add_time!).getTime() - new Date(a.add_time!).getTime());

        // Use a Set to ensure unique deals based on pipedrive_deal_id
        const uniqueDeals = Array.from(new Set(sortedDeals.map((deal) => deal.pipedrive_deal_id))).map((id) =>
          sortedDeals.find((deal) => deal.pipedrive_deal_id === id),
        );

        if (dealsResponse.data) {
          setFilteredDeals(uniqueDeals as DBDeal[]);

          const newDealsMap = new Map<number, DBDeal>();
          uniqueDeals
            .filter((deal): deal is DBDeal => deal !== undefined)
            .forEach((deal: DBDeal) => {
              if (deal.pipedrive_deal_id !== null) {
                newDealsMap.set(deal.pipedrive_deal_id, deal);
              }
            });
          setDealsMap(newDealsMap);
        }
      } catch (error) {
        console.error('Error fetching deals from DB:', error);
      }
      setIsRendering(false);
    };

    setIsLoading(true);
    if (Array.isArray(arbitratorDeals?.data) && arbitratorDeals?.data.length > 0 && !arbitratorDealsIsLoading) {
      const dealIds = arbitratorDeals.data
        .map((deal: DBDeal) => deal.pipedrive_deal_id)
        .filter((id: number | null) => id !== null) as number[];
      fetchDealsFromDB(dealIds);
    } else {
      setFilteredDeals([]);
    }
    setIsLoading(false);
  }, [arbitratorDeals, tabValue]);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const [toBeTreatedResponse, awaitingSupervisionResponse, treatedResponse] = await Promise.all([
          makeAuthenticatedApiCall('get', '/api/arbitration/count/to-be-treated'),
          makeAuthenticatedApiCall('get', '/api/arbitration/count/awaiting-supervision'),
          makeAuthenticatedApiCall('get', '/api/arbitration/count/treated'),
        ]);

        setDealsInfo({
          treaded: treatedResponse.data as number,
          toBeTreated: toBeTreatedResponse.data as number,
          awaitingSupervision: awaitingSupervisionResponse.data as number,
        });
      } catch (error) {
        console.error('Error fetching deal counts:', error);
      }
    };
    fetchCounts();
  }, []);

  const handleTabChange = async (_: React.SyntheticEvent, newValue: any) => {
    setTabValue(newValue);
    setIsLoading(true);
    await refetchArbitratorDeals();
    setIsLoading(false);
  };

  if (error) {
    return <div>Erreur lors du chargement des données</div>; // Ou une gestion plus avancée des erreurs
  }

  const handleRefresh = async () => {
    setIsLoading(true);
    try {
      await axios.post('/api/deals/synchronize');
      await refetchArbitratorDeals();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box>
        <Grid container item xs={12} sm={12} justifyContent="center">
          <Button
            type="button"
            variant="contained"
            sx={{
              fontWeight: 900,
              height: 55,
              margin: '16px auto',
              textAlign: 'center',
              backgroundColor: theme.palette.saffron.main,
              '&:hover': { backgroundColor: theme.palette.saffron.main },
            }}
            onClick={handleRefresh}
          >
            🔄 Rafraichir la liste
          </Button>
        </Grid>
      </Box>
      <Container maxWidth={false}>
        <Paper sx={{ marginTop: 5, padding: 5 }} elevation={3}>
          <Grid container item xs={12} sm={12} justifyContent="space-between">
            <Grid item xs={12} sm={8}>
              <Grid mt={2} display={'flex'} alignItems={'center'}>
                <Box>
                  <Typography variant="body2">
                    🔄{'  '}Deals à traiter : {dealsInfo.toBeTreated}
                  </Typography>
                  <Typography variant="body2">
                    ✅{'  '}Deals traités : {dealsInfo.treaded}
                  </Typography>

                  {userRole === 'god' && (
                    <Typography variant="body2">
                      👀 Nombre de deals en attente de supervision : {dealsInfo.awaitingSupervision}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid mt={2} display={'flex'} alignItems={'center'}>
                <Typography variant="body2">Charte Skipcar :</Typography>{' '}
                <Box style={{ marginLeft: 10, height: 10, width: 15, backgroundColor: 'green' }}></Box>
              </Grid>
              <Grid display={'flex'} alignItems={'center'}>
                <Typography variant="body2">Hors-charte : </Typography>
                <Box style={{ marginLeft: 10, height: 10, width: 15, backgroundColor: 'red' }}></Box>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        <Paper sx={{ marginTop: 5, padding: 0 }} elevation={3}>
          <Tabs value={tabValue} onChange={handleTabChange} centered className="DealsListTabs">
            <Tab label="Tous" value={0} />
            <Tab label="À Traiter" value={'to_be_processed'} />
            <Tab label="En attente de supervision" value={'awaiting_supervision'} />
            <Tab label="Traités" value={'processed'} />
            <Tab label="Arbitrages Refusés" value={'refused'} />
            <Tab label="Offres acceptées" value={'accepted'} />
          </Tabs>
          {isLoading || arbitratorDealsIsLoading || isRendering ? (
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Typography sx={{ margin: '1em auto 2em' }} variant="body1">
                Chargement
              </Typography>
              <CircularProgress size={70} sx={{ margin: '1em auto 2em' }} />
            </Grid>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ background: '#262626' }}>
                    <TableCell sx={{ fontWeight: 'bold', minWidth: 90, color: 'white' }}>État</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Date</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white', minWidth: 90 }}>Immat</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Agence</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Marque / Modèle</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Kilométrage</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>MEC</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white', minWidth: 90 }}>Prix</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white', minWidth: 130, textAlign: 'center' }}>
                      Infos
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>ID</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {!isLoading && !isRendering && !arbitratorDealsIsLoading && filteredDeals?.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={10} align="center">
                        <Typography>Aucun deal à traiter ! Bravo</Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {filteredDeals.map((deal: DBDeal, index: number) => {
                        const dealId = deal.pipedrive_deal_id ?? -1;
                        const dealDataInDB = dealsMap.get(dealId);

                        if (dealId !== -1) {
                          return (
                            <ArbitratorDeal
                              index={index}
                              key={`${deal.pipedrive_deal_id}-${index}`}
                              row={{
                                ...deal,
                                accepted_offer: dealDataInDB?.accepted_offer ?? null,
                                status: dealDataInDB?.status ?? null,
                              }}
                              userRole={userRole}
                              userEmail={userEmail}
                              refetchArbitratorDeals={refetchArbitratorDeals}
                              dealData={dealDataInDB}
                            />
                          );
                        }
                        return null;
                      })}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default ArbitratorDealsList;

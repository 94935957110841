import { useEffect, useState } from 'react';
import { JantesSvg } from './JantesSvg';
import { InterieurSvg } from './InterieurSvg';
import { RevisionSvg } from './RevisionSvg';
import { MecaniqueSvg } from './MecaniqueSvg';
import { AccessoiresSvg } from './AccessoiresSvg';
import { Box, Divider, Grid, Paper, Typography } from '@mui/material';
import { formatString } from '../../utils';
import { svgConfigDefaultUrlHash, svgDataHash } from '../../constants/svgHash';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import useFromExternalStore from '../../stores/FromExternalStore';

const FRE = ({
  onValidate,
  restorationTexts,
  setRestorationTexts,
  restorationValues,
  setRestorationValues,
  totalRestorationValue,
  setTotalRestorationValue,
}) => {
  const colors = [
    'svg_block formH',
    'svg_block formH yellow',
    'svg_block formH orange',
    'svg_block formH red',
    'svg_block formH blue',
  ];

  const [invalidIds, setInvalidIds] = useState([]);

  const isFromExternal = useFromExternalStore((state) => state.fromExternal);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const ajSvgClick = (id: string, onNewRepairText: string) => {
    let urlString = [];

    if (svgDataHash[id] && svgDataHash[id]['params']) {
      for (const k in svgDataHash[id]['params']) {
        let v = '';
        if (k === 'index') {
          let element = document.getElementById(id);
          if (element && element.value && element.value.includes('__--__')) {
            v = `${k}=${element.value.split('__--__')[1]}`;
          }
        }

        if (v === '') {
          v = `${k}=${svgDataHash[id]['params'][k]}`;
        }

        urlString.push(v);
      }
    } else {
      console.error("svgDataHash[id] ou svgDataHash[id]['params'] est undefined");
    }

    if (Object.keys(svgConfigDefaultUrlHash)?.length > 0) {
      for (let k in svgConfigDefaultUrlHash) {
        urlString.push(`${k}=${svgConfigDefaultUrlHash[k]}`);
      }
    }

    urlString.push('fds=mRMvHRpsA1tqBM6y9PYv1HnpHoOkEOYlcXGXxYYfuf2I9TJGN9');

    if (urlString?.length > 0) {
      urlString = urlString.join('&');

      fetch(svgDataHash[id]['post_url'], {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: urlString,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data && data.response && data.response.valid === 'true') {
            if (data.response.svg_val !== '') {
              const d_arr = data.response.svg_val.split('__--__');
              const element = document.getElementById(d_arr[0]);

              const elementsToFill = element?.parentElement;
              elementsToFill.className = colors[d_arr[2]];

              if (d_arr[1] == 'null') {
                element.value = '';
                onNewRepairText(id, '');

                setRestorationValues((prevValues) => {
                  const updatedValues = { ...prevValues };
                  delete updatedValues[d_arr[0]];

                  const newTotal = Object.values(updatedValues).reduce((acc, curr) => acc + curr, 0);
                  setTotalRestorationValue(newTotal);

                  return updatedValues;
                });
              } else {
                element.value = `${d_arr[1]}__--__${d_arr[2]}`;

                setRestorationValues((prevValues) => {
                  const updatedValues = {
                    ...prevValues,
                    [d_arr[0]]: data.response.val,
                  };

                  const newTotal = Object.values(updatedValues).reduce((acc, curr) => acc + curr, 0);
                  setTotalRestorationValue(newTotal);

                  return updatedValues;
                });

                if (data.response.text !== '') {
                  onNewRepairText(id, data.response.text);
                }
              }
              validateRequiredFields();
            }
          } else {
            console.log('erreur!');
          }
        })
        .catch(() => {
          console.log('major error !');
        });
    }
  };

  const validateRequiredFields = () => {
    const requiredIds = isFromExternal ? [] : ['grele', 'kit_distribution'];

    const invalidElements = requiredIds.filter((id) => {
      const element = document.querySelector(`#${id}`)?.parentElement;
      const classes = element?.className;
      return classes === 'svg_block formH';
    });
    setInvalidIds(invalidElements);

    const isValid = invalidElements?.length === 0;

    onValidate(isValid);
  };

  useEffect(() => {
    const elements = document.querySelectorAll('.svg_block svg[id]');

    const handleClick = (event) => {
      const elementId = event.currentTarget.id;
      const elementParent = event.currentTarget.parentElement;

      ajSvgClick(elementId, (id, newRepairText) => {
        return new Promise((resolve) => {
          const classes = elementParent.className;
          resolve(classes);
        }).then((classes) => {
          setRestorationTexts((prevRepairTexts) => ({
            ...prevRepairTexts,
            [id]: {
              text: newRepairText,
              classes: classes || 'svg_block formH',
            },
          }));
        });
      });
    };

    elements.forEach((element) => {
      element.addEventListener('click', handleClick);
    });

    return () => {
      elements.forEach((element) => {
        element.removeEventListener('click', handleClick);
      });
    };
  }, [isFromExternal]);

  useEffect(() => {
    if (isFromExternal) {
      onValidate(true); // Auto-validate when isFromExternal is true
    } else {
      validateRequiredFields();
    }
  }, [isFromExternal]);

  return (
    <Paper elevation={0} sx={{ backgroundColor: '#eeeeee' }}>
      <Grid container rowSpacing={6} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} sm={7}>
          <Typography variant="body1" align="center">
            Niveau de gravité
          </Typography>
          <Box id="svg_colour_indexes">
            <Box className="svg_col_index" style={{ backgroundColor: '#D8E1F0' }}></Box>
            <Box className="svg_col_index" style={{ backgroundColor: '#FFEECD' }}></Box>
            <Box className="svg_col_index" style={{ backgroundColor: '#F9c07a' }}></Box>
            <Box className="svg_col_index" style={{ backgroundColor: '#f97779' }}></Box>
            <Box className="svg_col_index" style={{ backgroundColor: '#979ed2' }}></Box>
          </Box>
          <Typography variant="body1" align="center" sx={{ fontWeight: 600, marginTop: 5 }}>
            Jantes / Nettoyages
          </Typography>
          <JantesSvg />
          <Divider variant="inset" component="div" />
          <Typography variant="body1" align="center" sx={{ fontWeight: 600, marginTop: 5 }}>
            Intérieur
          </Typography>
          <InterieurSvg />
          <Divider variant="inset" component="div" />
          <Typography variant="body1" align="center" sx={{ fontWeight: 600, marginTop: 5 }}>
            Révision
          </Typography>
          <RevisionSvg />
          <Divider variant="inset" component="div" />
          <Typography variant="body1" align="center" sx={{ fontWeight: 600, marginTop: 5 }}>
            Opérations mécaniques
          </Typography>
          <MecaniqueSvg />
          <Divider variant="inset" component="div" />
          <Typography variant="body1" align="center" sx={{ fontWeight: 600, marginTop: 5 }}>
            Accessoires
          </Typography>
          <AccessoiresSvg />
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          sx={{
            position: 'sticky',
            top: 0,
            overflowY: 'auto',
            maxHeight: '80vh',
          }}
        >
          {isFromExternal ? (
            <>
              <Box sx={{ marginBottom: 5 }}>
                <Typography align={isMobile ? 'center' : 'inherit'} variant="body1">
                  Frais à prévoir
                </Typography>
                <Typography align={isMobile ? 'center' : 'inherit'} variant="body2">
                  Cliquez sur chaque élément pour vérifier l'état
                </Typography>
              </Box>
            </>
          ) : (
            Object.values(restorationTexts)?.length < 1 && (
              <Typography align={isMobile ? 'center' : 'inherit'} variant="body1">
                Cliquez sur chaque élément pour vérifier l'état
              </Typography>
            )
          )}
          {Object.values(restorationTexts).map((repairText, index) => (
            <Typography align={isMobile ? 'center' : 'inherit'} variant="body2" key={index}>
              {formatString(repairText.text as any)}
            </Typography>
          ))}
          <Typography align={isMobile ? 'center' : 'inherit'} variant="body1" sx={{ fontWeight: 'bold', marginTop: 5 }}>
            Total : {totalRestorationValue} €
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FRE;

import React, { useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

import { useDailySellingStats } from '../../hooks/useApiQueries';
import { priceFormatter } from '../../utils';

const stickyCellStyle = {
  background: '#262626',
  fontWeight: 'bold',
  minWidth: 50,
  color: 'white',
  position: 'sticky',
  top: 0,
  zIndex: 100,
};

interface DailySellingStatsTableProps {
  franchise?: string;
  orgName?: string;
}

interface DailySellingStats {
  date: string;
  net_amount: number | null;
  vehicle_count: number;
  net_margin: number;
  details: Array<{
    pipedrive_deal_id: string;
    registration_plate: string;
    ext_url: string;
  }>;
}

function aggregateDailyStats(stats: DailySellingStats[]) {
  const aggregatedStats = stats.reduce((acc, stat) => {
    const date = stat.date.split('T')[0]; // Extraire la date sans l'heure
    if (!acc[date]) {
      acc[date] = {
        date: stat.date,
        net_amount: 0,
        vehicle_count: 0,
        net_margin: 0,
        details: [],
      };
    }
    acc[date].net_amount += parseFloat(stat.net_amount);
    acc[date].vehicle_count += stat.vehicle_count;
    acc[date].net_margin += stat.net_margin;
    if (stat.details) {
      acc[date].details = acc[date].details.concat(stat.details);
    }
    return acc;
  }, {});

  return Object.values(aggregatedStats);
}

const DailySellingStatsTable: React.FC<DailySellingStatsTableProps> = ({ franchise, orgName }) => {
  const { data: stats = [], isLoading } = useDailySellingStats(franchise, orgName) as {
    data: DailySellingStats[] | undefined;
    isLoading: boolean;
  };
  const aggregatedStats = aggregateDailyStats(stats);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState<DailySellingStats['details']>([]);

  if (isLoading || !stats) return null;

  const handleRowClick = (details: DailySellingStats['details']) => {
    if (details.length > 0) {
      const uniqueDetails = filterUniqueDetails(details);
      setSelectedDetails(uniqueDetails);
      setDialogOpen(true);
    }
  };

  const filterUniqueDetails = (details: DailySellingStats['details']): DailySellingStats['details'] => {
    const seen = new Set();
    return details.filter((detail) => {
      if (seen.has(detail.pipedrive_deal_id)) {
        return false;
      }
      seen.add(detail.pipedrive_deal_id);
      return true;
    });
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ background: '#262626' }}>
              <TableCell sx={{ fontWeight: 600, color: 'white' }}>Date</TableCell>
              <TableCell sx={{ fontWeight: 600, color: 'white' }}>Véhicules vendus</TableCell>
              <TableCell sx={{ fontWeight: 600, color: 'white' }}>Montant net</TableCell>
              <TableCell sx={{ fontWeight: 600, color: 'white' }}>Marge nette</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {aggregatedStats.map((stat: DailySellingStats, index: number) => (
              <TableRow
                key={`${stat.date}-${index}`}
                onClick={() => handleRowClick(stat.details)}
                sx={{ cursor: 'pointer' }}
                className="tableRow"
              >
                <TableCell className="tableCell">
                  {format(new Date(stat.date), 'dd MMM yyyy', { locale: fr })}
                </TableCell>
                <TableCell className="tableCell">{stat.vehicle_count}</TableCell>
                <TableCell className="tableCell">
                  {stat.net_amount !== null && !isNaN(stat.net_amount) ? priceFormatter.format(stat.net_amount) : 'N/A'}
                </TableCell>
                <TableCell className="tableCell">
                  {typeof stat.net_margin === 'number' ? `${stat.net_margin.toFixed(2)} €` : 'N/A'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Détails des ventes</DialogTitle>
        <DialogContent>
          <Paper sx={{ marginTop: 2 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={stickyCellStyle}>Pipedrive Deal Id</TableCell>
                  <TableCell sx={stickyCellStyle}>Plaque</TableCell>
                  <TableCell sx={stickyCellStyle}>Lien</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedDetails.map((detail, index) => (
                  <TableRow key={index}>
                    <TableCell>{detail.pipedrive_deal_id}</TableCell>
                    <TableCell>{detail.registration_plate}</TableCell>
                    <TableCell>
                      <a
                        style={{ color: 'black' }}
                        href={`${detail.ext_url}&arbitrator`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Voir la fiche
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DailySellingStatsTable;
